@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500);
@import url(https://px.animaapp.com/611cb643331562400fadc467.611cb646331562400fadc46a.qwurEh8.hcp.png);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,500);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500);
/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc All global CSS are defined here
 */

::-webkit-scrollbar {
  width: 6px !important;
} /* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.23) !important; 
  border-radius: 24px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(146, 151, 165, 1) !important; 
}
 /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */


body {
scrollbar-face-color: #f1f1f1 !important;
margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'manage_care_semibold';
  src: url(/static/media/proxima_nova_semibold.8feb512e.woff) format('woff');
}
@font-face {
	font-family: 'manage_care_bold';
  src: url(/static/media/proxima_nova_bold.e2cf3dc2.woff) format('woff');
}

.MuiPickersDateRangePickerInput-root{
  width: 100%;
}

/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
body {
	font-family: 'manage_care_semibold' !important;
}
code {
  font-family: 'manage_care_semibold' !important;
}
textarea:focus, input:focus{
  font-family: 'manage_care_semibold' !important;
}
input, select, textarea{
  font-family: 'manage_care_semibold' !important;
}

:-webkit-full-screen{
  z-index: 5;
}

:fullscreen{
  z-index: 5;
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/184567590) */

:root {
  --font-family-roboto-bmicz: "Roboto", Helvetica;
  --font-size-xl-1vm06: 20px;
  --font-size-s-j5fxn: 9px;
  --font-size-m-c3ctj: 14px;
  --font-size-l-gnk2q: 18px;
  --sonic-silver-7v8pu: #757171;
  --concord-5is5q: #7c7c7c;
  --black-8e71n: #000000;
}
.success-dialog {
  background-color: #ffffff;
  height: 470px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 415px;
}
.success-dialog .group-93701-MC2f8T {
  background-color: transparent;
  height: 534px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 415px;
}
.success-dialog .group-11-7B1UcH {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.success-dialog .rectangle-20-34L3Wo {
  background-color: #ffffff;
  border: 0.2px solid #707070;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.success-dialog .greentick-7B1UcH {
  background-color: transparent;
  height: 98px;
  left: calc(50% - 50px);
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 99px;
}
.success-dialog .registration-success-7B1UcH {
  background-color: transparent;
  color: #000000;
  color: var(--black-8e71n);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bmicz);
  font-size: 20px;
  font-size: var(--font-size-xl-1vm06);
  font-style: normal;
  font-weight: 500;
  height: 28px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  right: calc(49.46% - 114px);
  text-align: left;
  top: calc(27.08% - 8px);
  white-space: nowrap;
  width: 231px;
}
.success-dialog .group-13-7B1UcH {
  background-color: transparent;
  bottom: calc(21.14% - 13px);
  height: 61px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.success-dialog .rectangle-26-2s0qiD {
  background-color: #dff0d7;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.success-dialog .thank-you-for-the-re-2s0qiD {
  background-color: transparent;
  color: #0fa958;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bmicz);
  font-size: 9px;
  font-size: var(--font-size-s-j5fxn);
  font-style: normal;
  font-weight: 400;
  height: 13px;
  letter-spacing: 0px;
  line-height: 9px;
  position: absolute;
  right: calc(49.34% - 130px);
  text-align: left;
  top: 8px;
  white-space: nowrap;
  width: 263px;
}
.success-dialog .mrn-mjgu7643-k-2s0qiD {
  background-color: transparent;
  bottom: calc(16.25% - 3px);
  color: #1f5c0a;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bmicz);
  font-size: 14px;
  font-size: var(--font-size-m-c3ctj);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(49.65% - 66px);
  text-align: left;
  white-space: nowrap;
  width: 133px;
}
.success-dialog .close-7B1UcH {
  background-color: transparent;
  bottom: 35px;
  height: 24px;
  left: calc(49.86% - 23px);
  position: absolute;
  width: 46px;
}
.success-dialog .close-H39msy {
  background-color: transparent;
  color: #7c7c7c;
  color: var(--concord-5is5q);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bmicz);
  font-size: 18px;
  font-size: var(--font-size-l-gnk2q);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  top: 3px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.success-dialog .group-14-7B1UcH {
  background-color: transparent;
  height: 159px;
  left: calc(50% - 138px);
  position: absolute;
  top: calc(49.87% - 79px);
  width: 277px;
}
.success-dialog .patient-name-sly5Zj {
  background-color: transparent;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 85px;
}
.success-dialog .administrator-sly5Zj {
  background-color: transparent;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 88px;
}
.success-dialog .x1234567890-sly5Zj {
  background-color: transparent;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: 6px;
  text-align: left;
  top: calc(27.17% - 6px);
  white-space: nowrap;
  width: 82px;
}
.success-dialog .dfhs5-d7-sly5Zj {
  background-color: transparent;
  bottom: calc(47.46% - 10px);
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: 25px;
  text-align: left;
  white-space: nowrap;
  width: 62px;
}
.success-dialog .x654343-sly5Zj {
  background-color: transparent;
  bottom: calc(22.1% - 5px);
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: 38px;
  text-align: left;
  white-space: nowrap;
  width: 50px;
}
.success-dialog .malaysia-sly5Zj {
  background-color: transparent;
  bottom: -4px;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: 17px;
  text-align: left;
  white-space: nowrap;
  width: 70px;
}
.success-dialog .mobile-no-sly5Zj {
  background-color: transparent;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: calc(27.17% - 6px);
  white-space: nowrap;
  width: 66px;
}
.success-dialog .id-card-sly5Zj {
  background-color: transparent;
  bottom: calc(47.46% - 10px);
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 48px;
}
.success-dialog .emp-no-sly5Zj {
  background-color: transparent;
  bottom: calc(22.1% - 5px);
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 52px;
}
.success-dialog .facility-sly5Zj {
  background-color: transparent;
  bottom: -4px;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 46px;
}
.success-dialog .x-sly5Zj {
  background-color: transparent;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(47.62% - 3px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 6px;
}
.success-dialog .x-M4G9ma {
  background-color: transparent;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(47.62% - 3px);
  text-align: left;
  top: calc(27.17% - 6px);
  white-space: nowrap;
  width: 6px;
}
.success-dialog .x-w5nauq {
  background-color: transparent;
  bottom: calc(47.46% - 10px);
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(47.62% - 3px);
  text-align: left;
  white-space: nowrap;
  width: 6px;
}
.success-dialog .x-qq4qva {
  background-color: transparent;
  bottom: calc(22.1% - 5px);
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(47.62% - 3px);
  text-align: left;
  white-space: nowrap;
  width: 6px;
}
.success-dialog .x-vojvqU {
  background-color: transparent;
  bottom: -4px;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(47.62% - 3px);
  text-align: left;
  white-space: nowrap;
  width: 6px;
}
.roboto-normal-concord-14px {
  color: #7c7c7c;
  color: var(--concord-5is5q);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bmicz);
  font-size: 14px;
  font-size: var(--font-size-m-c3ctj);
  font-style: normal;
  font-weight: 400;
}
.roboto-medium-black-14px {
  color: #000000;
  color: var(--black-8e71n);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bmicz);
  font-size: 14px;
  font-size: var(--font-size-m-c3ctj);
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-sonic-silver-14px {
  color: #757171;
  color: var(--sonic-silver-7v8pu);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bmicz);
  font-size: 14px;
  font-size: var(--font-size-m-c3ctj);
  font-style: normal;
  font-weight: 500;
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}
.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}
* {
  box-sizing: border-box;
}


.logout_container {
  display: flex;
  align-items: center;
  margin: 5px 16px 5px 0px;
  color: gray;
  justify-content: flex-end;
}

.logout_container:hover {
  color: black !important;
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183775383) */

:root {
  --font-family-roboto-condensedbold-jvoae: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-1fxz2: "Roboto", Helvetica;
  --font-size-xxxxl-jym54: 23px;
  --font-size-xxxl-p5mu5: 20px;
  --font-size-xxl-eo7we: 19px;
  --font-size-xl-iln0b: 16px;
  --font-size-s-hw65g: 12px;
  --font-size-m-8b6g4: 14px;
  --font-size-l-9mvg2: 15.2px;
  --white-3lv7o: #ffffff;
  --pink-swan-q1k3w: #b6b6b6;
  --dove-gray-t1bwo: #6d6e6f;
  --bon-jour-ee12e: #e0e0e0;
  --blue-ribbon-2pap2: #0063e7;
  --black-azujx: #000000;
}
.add-patient-facility {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.add-patient-facility .group-93706-lMAO0f {
  background-color: transparent;
  height: 530px;
  position: absolute;
  right: calc(38.17% - 188px);
  top: 159px;
  width: 493px;
}
.add-patient-facility .rectangle-47165-qkxrM8 {
  background-color: #ffffff;
  background-color: var(--white-3lv7o);
  border: 0.5px solid #1010101a;
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-patient-facility .add-patient-facility-qkxrM8 {
  background-color: transparent;
  color: #000000;
  color: var(--black-azujx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 16px;
  font-size: var(--font-size-xl-iln0b);
  font-style: normal;
  font-weight: 500;
  height: 23px;
  left: 18px;
  letter-spacing: 0px;
  line-height: 16px;
  position: absolute;
  text-align: left;
  top: 16px;
  white-space: nowrap;
  width: 141px;
}
.add-patient-facility .line-3-qkxrM8 {
  background-color: transparent;
  height: 1px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 100%;
}
.add-patient-facility .qdm-textinput-qkxrM8 {
  background-color: transparent;
  bottom: calc(41.6% - 12px);
  height: 30px;
  left: 18px;
  position: absolute;
  width: calc(100% - 38px);
}
.add-patient-facility .textinput-SBK5Sz {
  background-color: #ffffff;
  background-color: var(--white-3lv7o);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-patient-facility .enter-address-SBK5Sz {
  background-color: transparent;
  border: 0;
  bottom: calc(42.44% - 8px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 79px;
}
.add-patient-facility .enter-address-SBK5Sz::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-patient-facility .enter-address-SBK5Sz::placeholder {
  color: #b6b6b699;
}
.add-patient-facility .qdm-textinput-TtxiSe {
  background-color: transparent;
  bottom: calc(27.42% - 8px);
  height: 30px;
  left: 19px;
  position: absolute;
  width: calc(100% - 38px);
}
.add-patient-facility .textinput-r6c1sD {
  background-color: #ffffff;
  background-color: var(--white-3lv7o);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-patient-facility .enter-postal-code-r6c1sD {
  background-color: transparent;
  border: 0;
  bottom: calc(42.44% - 8px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 100px;
}
.add-patient-facility .enter-postal-code-r6c1sD::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-patient-facility .enter-postal-code-r6c1sD::placeholder {
  color: #b6b6b699;
}
.add-patient-facility .qdm-dropdown-qkxrM8 {
  background-color: transparent;
  height: 30px;
  left: 18px;
  position: absolute;
  top: calc(44.22% - 13px);
  width: calc(100% - 40px);
}
.add-patient-facility .rectangle-47161-usqgtU {
  background-color: #ffffff;
  background-color: var(--white-3lv7o);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-patient-facility .select-usqgtU {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 34px;
}
.add-patient-facility .group-92805-usqgtU {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.add-patient-facility .icons8-expand-arrow-OaU6rt {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.add-patient-facility .qdm-dropdown-TtxiSe {
  background-color: transparent;
  height: 30px;
  left: 18px;
  position: absolute;
  top: calc(30.04% - 9px);
  width: calc(100% - 40px);
}
.add-patient-facility .rectangle-47161-5tm5Qc {
  background-color: #ffffff;
  background-color: var(--white-3lv7o);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-patient-facility .select-5tm5Qc {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 34px;
}
.add-patient-facility .group-92805-5tm5Qc {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.add-patient-facility .icons8-expand-arrow-9ofy5e {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.add-patient-facility .group-93708-qkxrM8 {
  background-color: transparent;
  bottom: 55px;
  height: 16px;
  left: 19px;
  position: absolute;
  width: 37px;
}
.add-patient-facility .back-jdWd57 {
  background-color: transparent;
  color: #000000d1;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 12px;
  font-size: var(--font-size-s-hw65g);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 29px;
}
.add-patient-facility .path-734-jdWd57 {
  background-color: transparent;
  bottom: calc(38.94% - 3px);
  height: 7px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  width: 7px;
}
.add-patient-facility .qdm-button-qkxrM8 {
  background-color: transparent;
  bottom: 49px;
  height: 26px;
  position: absolute;
  right: 19px;
  width: 84px;
}
.add-patient-facility .rectangle-45330-eNmFnb {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-2pap2);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-patient-facility .submit-eNmFnb {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-2pap2);
  color: #ffffff;
  color: var(--white-3lv7o);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 14px;
  font-size: var(--font-size-m-8b6g4);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 7px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
.add-patient-facility .qdm-dropdown-mwqDjR {
  background-color: transparent;
  height: 30px;
  left: 18px;
  position: absolute;
  top: 79px;
  width: calc(100% - 38px);
}
.add-patient-facility .textinput-ZacfOd {
  background-color: #ffffff;
  background-color: var(--white-3lv7o);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-patient-facility .facility-name-ZacfOd {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 11px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 75px;
}
.add-patient-facility .facility-name-ZacfOd::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-patient-facility .facility-name-ZacfOd::placeholder {
  color: #b6b6b699;
}
.add-patient-facility .group-93716-ZacfOd {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 11px;
  top: 4px;
  width: 24px;
}
.add-patient-facility .icons8-expand-arrow-JgEFkB {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.add-patient-facility .group-93711-lMAO0f {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
}
.add-patient-facility .rectangle-47173-kKjjEM {
  background-color: #ffffff;
  background-color: var(--white-3lv7o);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-right: 1px solid lightgrey;
}
.add-patient-facility .group-93715-kKjjEM {
  background-color: transparent;
  height: 424px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.add-patient-facility .logo-St6QWm {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.add-patient-facility .x51-client_profile_subscription-10-4I3GO3 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.add-patient-facility .global-doctors-4I3GO3 {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-2pap2);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 23px;
  font-size: var(--font-size-xxxxl-jym54);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.add-patient-facility .span0-eCR2Na {
  font-family: var(--font-family-roboto-1fxz2-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.add-patient-facility .span1-eCR2Na {
  font-size: 19px;
  font-size: var(--font-size-xxl-eo7we);
  font-style: normal;
}
.add-patient-facility .login-details-St6QWm {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.53% - 26px);
  width: 148px;
}
.add-patient-facility .hello-WisTeV {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 15.2px;
  font-size: var(--font-size-l-9mvg2);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.add-patient-facility .mr-rakin-WisTeV {
  background-color: transparent;
  color: #000000;
  color: var(--black-azujx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.add-patient-facility .sathishrakinainqacom-WisTeV {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-azujx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.add-patient-facility .line-9-WisTeV {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.add-patient-facility .reports-St6QWm {
  background-color: transparent;
  bottom: 40px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.add-patient-facility .reports-JpmTmj {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.add-patient-facility .download-7-JpmTmj {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.add-patient-facility .patient-regn-St6QWm {
  background-color: transparent;
  bottom: calc(44.31% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.add-patient-facility .patient-registration-EdZGZ0 {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.add-patient-facility .rectangle-47175-EdZGZ0 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.add-patient-facility .rectangle-47179-St6QWm {
  background-color: #ece3d3;
  border-radius: 5px;
  bottom: 98px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.add-patient-facility .master-St6QWm {
  background-color: transparent;
  bottom: 106px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.add-patient-facility .master-ERWKaM {
  background-color: transparent;
  color: #000000;
  color: var(--black-azujx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 14px;
  font-size: var(--font-size-m-8b6g4);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.add-patient-facility .material-master-data-ERWKaM {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.add-patient-facility .group-93714-St6QWm {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.add-patient-facility .visit-registration-TD0Ne6 {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.add-patient-facility .download-6-TD0Ne6 {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.add-patient-facility .version-lMAO0f {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.add-patient-facility .v1001-xRorvX {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.add-patient-facility .specialist-doctor-lMAO0f {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 14px;
  font-size: var(--font-size-m-8b6g4);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(22.97% - 25px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 108px;
}
.add-patient-facility .onsite-doctor-medical-assistant-lMAO0f {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 14px;
  font-size: var(--font-size-m-8b6g4);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(37.95% - 77px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 204px;
}
.add-patient-facility .patient-facility-lMAO0f {
  background-color: transparent;
  height: 30px;
  position: absolute;
  right: calc(46.74% - 43px);
  top: 99px;
  width: 93px;
}
.add-patient-facility .patient-facility-yxGVMi {
  background-color: transparent;
  color: #000000;
  color: var(--black-azujx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 14px;
  font-size: var(--font-size-m-8b6g4);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.add-patient-facility .rectangle-47182-yxGVMi {
  background-color: transparent;
  bottom: 0px;
  height: 3px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 6px);
}
.add-patient-facility .group-93712-lMAO0f {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.add-patient-facility .rectangle-47180-Run8od {
  background-color: #ffffff;
  background-color: var(--white-3lv7o);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}
.add-patient-facility .login-avatar-Run8od {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 19px;
  top: 18px;
  width: 134px;
}
.add-patient-facility .rectangle-47177-Vy2c0I {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-2pap2);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-patient-facility .rectangle-47178-Vy2c0I {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.add-patient-facility .rakin-Vy2c0I {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-2pap2);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 14px;
  font-size: var(--font-size-m-8b6g4);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.add-patient-facility .icon-awesome-chevron-down-Vy2c0I {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.add-patient-facility .master-Run8od {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-azujx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 59px;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
input:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
.roboto-light-pink-swan-12px {
  color: #b6b6b6;
  color: var(--pink-swan-q1k3w);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 12px;
  font-size: var(--font-size-s-hw65g);
  font-style: normal;
  font-weight: 300;
}
.roboto-medium-dove-gray-14px {
  color: #6d6e6f;
  color: var(--dove-gray-t1bwo);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-1fxz2);
  font-size: 14px;
  font-size: var(--font-size-m-8b6g4);
  font-style: normal;
  font-weight: 500;
}
.border-1px-bon-jour {
  border: 1px solid #e0e0e0;
  border: 1px solid var(--bon-jour-ee12e);
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183782638) */

:root {
  --font-family-roboto-condensedbold-n6wtn: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-339n3: "Roboto", Helvetica;
  --font-size-xxxxl-2uhig: 23px;
  --font-size-xxxl-26onn: 20px;
  --font-size-xxl-hw93u: 19px;
  --font-size-xl-2d3gp: 16px;
  --font-size-s-11kc9: 12px;
  --font-size-m-r3dyc: 14px;
  --font-size-l-9yaex: 15.2px;
  --white-bkrco: #ffffff;
  --pink-swan-kajqf: #b6b6b6;
  --dove-gray-ecs5q: #6d6e6f;
  --bon-jour-lyj99: #e0e0e0;
  --blue-ribbon-qd8z5: #0063e7;
  --black-9swxj: #000000;
}
.edit-onsite-doctor {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.edit-onsite-doctor .group-93711-cRzB4N {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
}
.edit-onsite-doctor .rectangle-47173-rIQkV4 {
  background-color: #ffffff;
  background-color: var(--white-bkrco);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .group-93715-rIQkV4 {
  background-color: transparent;
  height: 424px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.edit-onsite-doctor .logo-slvjiZ {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.edit-onsite-doctor .x51-client_profile_subscription-10-enxFdo {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.edit-onsite-doctor .global-doctors-enxFdo {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-qd8z5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 23px;
  font-size: var(--font-size-xxxxl-2uhig);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.edit-onsite-doctor .span0-7RY40Z {
  font-family: var(--font-family-roboto-339n3-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.edit-onsite-doctor .span1-7RY40Z {
  font-size: 19px;
  font-size: var(--font-size-xxl-hw93u);
  font-style: normal;
}
.edit-onsite-doctor .login-details-slvjiZ {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.53% - 26px);
  width: 148px;
}
.edit-onsite-doctor .hello-qSgli1 {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 15.2px;
  font-size: var(--font-size-l-9yaex);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.edit-onsite-doctor .mr-rakin-qSgli1 {
  background-color: transparent;
  color: #000000;
  color: var(--black-9swxj);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.edit-onsite-doctor .sathishrakinainqacom-qSgli1 {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-9swxj);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.edit-onsite-doctor .line-9-qSgli1 {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.edit-onsite-doctor .reports-slvjiZ {
  background-color: transparent;
  bottom: 40px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.edit-onsite-doctor .reports-lFxr1v {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.edit-onsite-doctor .download-7-lFxr1v {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.edit-onsite-doctor .patient-regn-slvjiZ {
  background-color: transparent;
  bottom: calc(44.31% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.edit-onsite-doctor .patient-registration-kp6L8r {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.edit-onsite-doctor .rectangle-47175-kp6L8r {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.edit-onsite-doctor .rectangle-47179-slvjiZ {
  background-color: #fff9ed;
  border-radius: 5px;
  bottom: 98px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.edit-onsite-doctor .master-slvjiZ {
  background-color: transparent;
  bottom: 106px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.edit-onsite-doctor .master-bJA2fF {
  background-color: transparent;
  color: #000000;
  color: var(--black-9swxj);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 14px;
  font-size: var(--font-size-m-r3dyc);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.edit-onsite-doctor .material-master-data-bJA2fF {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.edit-onsite-doctor .group-93714-slvjiZ {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.edit-onsite-doctor .visit-registration-cKNEBx {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.edit-onsite-doctor .download-6-cKNEBx {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.edit-onsite-doctor .version-cRzB4N {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.edit-onsite-doctor .v1001-wx79Te {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.edit-onsite-doctor .specialist-doctor-cRzB4N {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 14px;
  font-size: var(--font-size-m-r3dyc);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(22.97% - 25px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 108px;
}
.edit-onsite-doctor .patient-facility-cRzB4N {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 14px;
  font-size: var(--font-size-m-r3dyc);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(46.74% - 43px);
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 93px;
}
.edit-onsite-doctor .onsite-doctor-cRzB4N {
  background-color: transparent;
  height: 30px;
  left: calc(37.95% - 77px);
  position: absolute;
  top: 99px;
  width: 204px;
}
.edit-onsite-doctor .onsite-doctor-medical-assistant-fNMvBN {
  background-color: transparent;
  color: #000000;
  color: var(--black-9swxj);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 14px;
  font-size: var(--font-size-m-r3dyc);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.edit-onsite-doctor .rectangle-47181-fNMvBN {
  background-color: transparent;
  bottom: 0px;
  height: 3px;
  left: calc(46.48% - 63px);
  object-fit: cover;
  position: absolute;
  width: 135px;
}
.edit-onsite-doctor .group-93712-cRzB4N {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.edit-onsite-doctor .rectangle-47180-tEkdlU {
  background-color: #ffffff;
  background-color: var(--white-bkrco);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .login-avatar-tEkdlU {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 134px;
}
.edit-onsite-doctor .rectangle-47177-OKekXy {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-qd8z5);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .rectangle-47178-OKekXy {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.edit-onsite-doctor .rakin-OKekXy {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-qd8z5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 14px;
  font-size: var(--font-size-m-r3dyc);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.edit-onsite-doctor .icon-awesome-chevron-down-OKekXy {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.edit-onsite-doctor .master-tEkdlU {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-9swxj);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 59px;
}
.edit-onsite-doctor .group-93704-cRzB4N {
  background-color: transparent;
  bottom: 38px;
  height: 600px;
  position: absolute;
  right: calc(37.06% - 188px);
  width: 508px;
}
.edit-onsite-doctor .rectangle-47165-9By8Qq {
  background-color: #ffffff;
  background-color: var(--white-bkrco);
  border: 0.5px solid #1010101a;
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .edit-onsite-doctor-medical-assistant-9By8Qq {
  background-color: transparent;
  color: #000000;
  color: var(--black-9swxj);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 16px;
  font-size: var(--font-size-xl-2d3gp);
  font-style: normal;
  font-weight: 500;
  height: 23px;
  left: 26px;
  letter-spacing: 0px;
  line-height: 16px;
  position: absolute;
  text-align: left;
  top: 20px;
  white-space: nowrap;
  width: 266px;
}
.edit-onsite-doctor .line-3-9By8Qq {
  background-color: transparent;
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 100%;
}
.edit-onsite-doctor .qdm-list-9By8Qq {
  background-color: transparent;
  left: 26px;
  position: absolute;
  top: calc(50% - 205px);
  width: 100%;
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: scroll;
}
.edit-onsite-doctor .listitem-4STPMu {
  background-color: transparent;
  position: relative;
  width: calc(100% - 52px);
  height: 410px;
  margin: 0px 10px 10px 0px;
}
.edit-onsite-doctor .qdm-textinput-z8K7jg {
  background-color: transparent;
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 1px);
}
.edit-onsite-doctor .textinput-GpJfIm {
  background-color: #ffffff;
  background-color: var(--white-bkrco);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .enter-full-name-GpJfIm {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 85px;
}
.edit-onsite-doctor .enter-full-name-GpJfIm::-webkit-input-placeholder {
  color: #b6b6b699;
}
.edit-onsite-doctor .enter-full-name-GpJfIm::placeholder {
  color: #b6b6b699;
}
.edit-onsite-doctor .qdm-textinput-X3cWcD {
  background-color: transparent;
  bottom: calc(40% - 12px);
  height: 30px;
  left: 1px;
  position: absolute;
  width: calc(100% - 1px);
}
.edit-onsite-doctor .textinput-bxe8ex {
  background-color: #ffffff;
  background-color: var(--white-bkrco);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .enter-doctor-email-bxe8ex {
  background-color: transparent;
  border: 0;
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: calc(50% - 9px);
  white-space: nowrap;
  width: 105px;
}
.edit-onsite-doctor .enter-doctor-email-bxe8ex::-webkit-input-placeholder {
  color: #b6b6b699;
}
.edit-onsite-doctor .enter-doctor-email-bxe8ex::placeholder {
  color: #b6b6b699;
}
.edit-onsite-doctor .qdm-textinput-Qwci3K {
  background-color: transparent;
  bottom: 0px;
  height: 30px;
  left: 1px;
  position: absolute;
  width: calc(100% - 1px);
}
.edit-onsite-doctor .textinput-LOLsdx {
  background-color: #ffffff;
  background-color: var(--white-bkrco);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .enter-address-LOLsdx {
  background-color: transparent;
  border: 0;
  bottom: calc(42.44% - 8px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 79px;
}
.edit-onsite-doctor .enter-address-LOLsdx::-webkit-input-placeholder {
  color: #b6b6b699;
}
.edit-onsite-doctor .enter-address-LOLsdx::placeholder {
  color: #b6b6b699;
}
.edit-onsite-doctor .qdm-dropdown-z8K7jg {
  background-color: transparent;
  height: 30px;
  left: 1px;
  position: absolute;
  top: calc(40% - 12px);
  width: calc(100% - 3px);
}
.edit-onsite-doctor .rectangle-47161-pANEJj {
  background-color: #ffffff;
  background-color: var(--white-bkrco);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .select-pANEJj {
  background-color: transparent;
  bottom: calc(137.5% - 25px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 34px;
}
.edit-onsite-doctor .group-92805-pANEJj {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.edit-onsite-doctor .icons8-expand-arrow-rFrWpx {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.edit-onsite-doctor .qdm-dropdown-X3cWcD {
  background-color: transparent;
  height: 30px;
  left: 0px;
  position: absolute;
  top: calc(20% - 6px);
  width: calc(100% - 3px);
}
.edit-onsite-doctor .rectangle-47161-JixNec {
  background-color: #ffffff;
  background-color: var(--white-bkrco);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .select-JixNec {
  background-color: transparent;
  bottom: calc(137.5% - 25px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 34px;
}
.edit-onsite-doctor .group-92805-JixNec {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.edit-onsite-doctor .icons8-expand-arrow-zm1ttY {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.edit-onsite-doctor .qdm-textinput-bsB94u {
  background-color: transparent;
  bottom: calc(20% - 6px);
  height: 30px;
  left: 0px;
  position: absolute;
  width: calc(100% - 1px);
}
.edit-onsite-doctor .rectangle-42910-Hpxx7t {
  background-color: #ffffff;
  background-color: var(--white-bkrco);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .enter-contact-number-Hpxx7t {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: calc(35.14% - 43px);
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 121px;
}
.edit-onsite-doctor .enter-contact-number-Hpxx7t::-webkit-input-placeholder {
  color: #b6b6b699;
}
.edit-onsite-doctor .enter-contact-number-Hpxx7t::placeholder {
  color: #b6b6b699;
}
.edit-onsite-doctor .group1234567-Hpxx7t {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 88px;
}
.edit-onsite-doctor .rectangle-47161-sMJL5x {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .x91-sMJL5x {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  color: #b6b6b6;
  color: var(--pink-swan-kajqf);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 12px;
  font-size: var(--font-size-s-11kc9);
  font-style: normal;
  font-weight: 400;
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 15px;
}
.edit-onsite-doctor .group-92805-sMJL5x {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 8px;
  top: 3px;
  width: 24px;
}
.edit-onsite-doctor .icons8-expand-arrow-ieERcF {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.edit-onsite-doctor .group-93708-9By8Qq {
  background-color: transparent;
  bottom: 28px;
  height: 16px;
  left: 28px;
  position: absolute;
  width: 37px;
}
.edit-onsite-doctor .back-3mAaJr {
  background-color: transparent;
  color: #000000d1;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 12px;
  font-size: var(--font-size-s-11kc9);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 29px;
}
.edit-onsite-doctor .path-734-3mAaJr {
  background-color: transparent;
  bottom: calc(38.94% - 3px);
  height: 7px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  width: 7px;
}
.edit-onsite-doctor .qdm-button-9By8Qq {
  background-color: transparent;
  bottom: 22px;
  height: 26px;
  position: absolute;
  right: 27px;
  width: 84px;
}
.edit-onsite-doctor .rectangle-45330-P5EMta {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-qd8z5);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-onsite-doctor .submit-P5EMta {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-qd8z5);
  color: #ffffff;
  color: var(--white-bkrco);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 14px;
  font-size: var(--font-size-m-r3dyc);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 7px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
input:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
.roboto-light-pink-swan-12px {
  color: #b6b6b6;
  color: var(--pink-swan-kajqf);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 12px;
  font-size: var(--font-size-s-11kc9);
  font-style: normal;
  font-weight: 300;
}
.roboto-medium-dove-gray-14px {
  color: #6d6e6f;
  color: var(--dove-gray-ecs5q);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-339n3);
  font-size: 14px;
  font-size: var(--font-size-m-r3dyc);
  font-style: normal;
  font-weight: 500;
}
.border-1px-bon-jour {
  border: 1px solid #e0e0e0;
  border: 1px solid var(--bon-jour-lyj99);
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183774040) */

:root {
  --font-family-roboto-condensedbold-yhcpw: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-0t6nd: "Roboto", Helvetica;
  --font-size-xxxxl-uh56n: 23px;
  --font-size-xxxl-3mizz: 20px;
  --font-size-xxl-mkd5d: 19px;
  --font-size-xl-fgj2g: 16px;
  --font-size-s-g48yb: 12px;
  --font-size-m-rvp2k: 14px;
  --font-size-l-0fp9y: 15.2px;
  --white-im3wy: #ffffff;
  --pink-swan-cocxz: #b6b6b6;
  --dove-gray-fxtu4: #6d6e6f;
  --bon-jour-4qlvz: #e0e0e0;
  --blue-ribbon-pbe3j: #0063e7;
  --black-3tkqd: #000000;
}
.add-onsite-doctor {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.add-onsite-doctor .group-93711-xaSxgS {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
}
.add-onsite-doctor .rectangle-47173-uFZgGH {
  background-color: #ffffff;
  background-color: var(--white-im3wy);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-right: 1px solid lightgrey;
}
.add-onsite-doctor .group-93715-uFZgGH {
  background-color: transparent;
  height: 424px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.add-onsite-doctor .logo-EIHDxv {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.add-onsite-doctor .x51-client_profile_subscription-10-AkHeNh {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.add-onsite-doctor .global-doctors-AkHeNh {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-pbe3j);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 23px;
  font-size: var(--font-size-xxxxl-uh56n);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.add-onsite-doctor .span0-W05HrE {
  font-family: var(--font-family-roboto-0t6nd-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.add-onsite-doctor .span1-W05HrE {
  font-size: 19px;
  font-size: var(--font-size-xxl-mkd5d);
  font-style: normal;
}
.add-onsite-doctor .login-details-EIHDxv {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.53% - 26px);
  width: 148px;
}
.add-onsite-doctor .hello-7sMubk {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 15.2px;
  font-size: var(--font-size-l-0fp9y);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.add-onsite-doctor .mr-rakin-7sMubk {
  background-color: transparent;
  color: #000000;
  color: var(--black-3tkqd);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.add-onsite-doctor .sathishrakinainqacom-7sMubk {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-3tkqd);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.add-onsite-doctor .line-9-7sMubk {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.add-onsite-doctor .reports-EIHDxv {
  background-color: transparent;
  bottom: 40px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.add-onsite-doctor .reports-5fV0xx {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.add-onsite-doctor .download-7-5fV0xx {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.add-onsite-doctor .patient-regn-EIHDxv {
  background-color: transparent;
  bottom: calc(44.31% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.add-onsite-doctor .patient-registration-zxLbJl {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.add-onsite-doctor .rectangle-47175-zxLbJl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.add-onsite-doctor .rectangle-47179-EIHDxv {
  background-color: #ece3d3;
  border-radius: 5px;
  bottom: 98px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.add-onsite-doctor .master-EIHDxv {
  background-color: transparent;
  bottom: 106px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.add-onsite-doctor .master-MQE0f4 {
  background-color: transparent;
  color: #000000;
  color: var(--black-3tkqd);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 14px;
  font-size: var(--font-size-m-rvp2k);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.add-onsite-doctor .material-master-data-MQE0f4 {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.add-onsite-doctor .group-93714-EIHDxv {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.add-onsite-doctor .visit-registration-t3t4kL {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.add-onsite-doctor .download-6-t3t4kL {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.add-onsite-doctor .version-xaSxgS {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.add-onsite-doctor .v1001-5QYxRx {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.add-onsite-doctor .specialist-doctor-xaSxgS {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 14px;
  font-size: var(--font-size-m-rvp2k);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(22.97% - 25px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 108px;
}
.add-onsite-doctor .patient-facility-xaSxgS {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 14px;
  font-size: var(--font-size-m-rvp2k);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(46.74% - 43px);
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 93px;
}
.add-onsite-doctor .onsite-doctor-xaSxgS {
  background-color: transparent;
  height: 30px;
  left: calc(37.95% - 77px);
  position: absolute;
  top: 99px;
  width: 204px;
}
.add-onsite-doctor .onsite-doctor-medical-assistant-LYBOw5 {
  background-color: transparent;
  color: #000000;
  color: var(--black-3tkqd);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 14px;
  font-size: var(--font-size-m-rvp2k);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.add-onsite-doctor .rectangle-47181-LYBOw5 {
  background-color: transparent;
  bottom: 0px;
  height: 3px;
  left: calc(46.48% - 63px);
  object-fit: cover;
  position: absolute;
  width: 135px;
}
.add-onsite-doctor .group-93712-xaSxgS {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.add-onsite-doctor .rectangle-47180-Td8rXh {
  background-color: #ffffff;
  background-color: var(--white-im3wy);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}
.add-onsite-doctor .login-avatar-Td8rXh {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 134px;
}
.add-onsite-doctor .rectangle-47177-gh1v2R {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-pbe3j);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .rectangle-47178-gh1v2R {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.add-onsite-doctor .rakin-gh1v2R {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-pbe3j);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 14px;
  font-size: var(--font-size-m-rvp2k);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.add-onsite-doctor .icon-awesome-chevron-down-gh1v2R {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.add-onsite-doctor .master-Td8rXh {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-3tkqd);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 59px;
}
.add-onsite-doctor .group-93704-xaSxgS {
  background-color: transparent;
  height: 586px;
  position: absolute;
  right: calc(37.06% - 188px);
  top: 156px;
  width: 508px;
}
.add-onsite-doctor .rectangle-47165-5k5OiQ {
  background-color: #ffffff;
  background-color: var(--white-im3wy);
  border: 0.5px solid #1010101a;
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .add-onsite-doctor-medical-assistant-5k5OiQ {
  background-color: transparent;
  color: #000000;
  color: var(--black-3tkqd);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 16px;
  font-size: var(--font-size-xl-fgj2g);
  font-style: normal;
  font-weight: 500;
  height: 23px;
  left: 26px;
  letter-spacing: 0px;
  line-height: 16px;
  position: absolute;
  text-align: left;
  top: 18px;
  white-space: nowrap;
  width: 267px;
}
.add-onsite-doctor .line-3-5k5OiQ {
  background-color: transparent;
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 100%;
}
.add-onsite-doctor .component-28-5k5OiQ {
  background-color: transparent;
  height: 30px;
  left: 26px;
  position: absolute;
  top: calc(15.84% - 5px);
  width: calc(100% - 53px);
}
.add-onsite-doctor .qdm-textinput-f7PnHK {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .textinput-yw0OlP {
  background-color: #ffffff;
  background-color: var(--white-im3wy);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .enter-full-name-yw0OlP {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 85px;
}
.add-onsite-doctor .enter-full-name-yw0OlP::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-onsite-doctor .enter-full-name-yw0OlP::placeholder {
  color: #b6b6b699;
}
.add-onsite-doctor .component-70-5k5OiQ {
  background-color: transparent;
  bottom: calc(46.31% - 14px);
  height: 30px;
  left: 26px;
  position: absolute;
  width: calc(100% - 53px);
}
.add-onsite-doctor .qdm-textinput-tRM7QO {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .textinput-xVkfDR {
  background-color: #ffffff;
  background-color: var(--white-im3wy);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .enter-doctor-email-xVkfDR {
  background-color: transparent;
  border: 0;
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: calc(50% - 9px);
  white-space: nowrap;
  width: 105px;
}
.add-onsite-doctor .enter-doctor-email-xVkfDR::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-onsite-doctor .enter-doctor-email-xVkfDR::placeholder {
  color: #b6b6b699;
}
.add-onsite-doctor .component-71-5k5OiQ {
  background-color: transparent;
  bottom: calc(21.08% - 6px);
  height: 30px;
  left: 26px;
  position: absolute;
  width: calc(100% - 53px);
}
.add-onsite-doctor .qdm-textinput-WuHEB3 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .textinput-292H7f {
  background-color: #ffffff;
  background-color: var(--white-im3wy);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .enter-address-292H7f {
  background-color: transparent;
  border: 0;
  bottom: calc(42.44% - 8px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 79px;
}
.add-onsite-doctor .enter-address-292H7f::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-onsite-doctor .enter-address-292H7f::placeholder {
  color: #b6b6b699;
}
.add-onsite-doctor .component-93700-5k5OiQ {
  background-color: transparent;
  height: 30px;
  left: 26px;
  position: absolute;
  top: calc(41.07% - 12px);
  width: calc(100% - 55px);
}
.add-onsite-doctor .qdm-dropdown-OzmzFQ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .rectangle-47161-pmGUuJ {
  background-color: #ffffff;
  background-color: var(--white-im3wy);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .select-pmGUuJ {
  background-color: transparent;
  bottom: calc(137.5% - 25px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 34px;
}
.add-onsite-doctor .group-92805-pmGUuJ {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.add-onsite-doctor .icons8-expand-arrow-Eqx6SW {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.add-onsite-doctor .component-93700-4UlSRf {
  background-color: transparent;
  height: 30px;
  left: 26px;
  position: absolute;
  top: calc(28.46% - 9px);
  width: calc(100% - 55px);
}
.add-onsite-doctor .qdm-dropdown-Mdxby5 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .rectangle-47161-JePSTI {
  background-color: #ffffff;
  background-color: var(--white-im3wy);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .select-JePSTI {
  background-color: transparent;
  bottom: calc(137.5% - 25px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 34px;
}
.add-onsite-doctor .group-92805-JePSTI {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.add-onsite-doctor .icons8-expand-arrow-AEKfpz {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.add-onsite-doctor .component-93700-dF6Wax {
  background-color: transparent;
  bottom: calc(33.7% - 10px);
  height: 30px;
  left: 26px;
  position: absolute;
  width: calc(100% - 53px);
}
.add-onsite-doctor .qdm-textinput-Op6AGD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .rectangle-42910-xNOeKT {
  background-color: #ffffff;
  background-color: var(--white-im3wy);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .enter-contact-number-xNOeKT {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: calc(35.14% - 43px);
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 121px;
}
.add-onsite-doctor .enter-contact-number-xNOeKT::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-onsite-doctor .enter-contact-number-xNOeKT::placeholder {
  color: #b6b6b699;
}
.add-onsite-doctor .group1234567-xNOeKT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 88px;
}
.add-onsite-doctor .rectangle-47161-sBKmvr {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .x91-sBKmvr {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  color: #b6b6b6;
  color: var(--pink-swan-cocxz);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 12px;
  font-size: var(--font-size-s-g48yb);
  font-style: normal;
  font-weight: 400;
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 15px;
}
.add-onsite-doctor .group-92805-sBKmvr {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 8px;
  top: 3px;
  width: 24px;
}
.add-onsite-doctor .icons8-expand-arrow-mkwOeD {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.add-onsite-doctor .group-93708-5k5OiQ {
  background-color: transparent;
  bottom: 46px;
  height: 16px;
  left: 26px;
  position: absolute;
  width: 37px;
}
.add-onsite-doctor .back-Mo1rQz {
  background-color: transparent;
  color: #000000d1;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 12px;
  font-size: var(--font-size-s-g48yb);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 29px;
}
.add-onsite-doctor .path-734-Mo1rQz {
  background-color: transparent;
  bottom: calc(38.94% - 3px);
  height: 7px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  width: 7px;
}
.add-onsite-doctor .qdm-button-5k5OiQ {
  background-color: transparent;
  bottom: 40px;
  height: 26px;
  position: absolute;
  right: 27px;
  width: 84px;
}
.add-onsite-doctor .rectangle-45330-rY6VxM {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-pbe3j);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-onsite-doctor .submit-rY6VxM {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-pbe3j);
  color: #ffffff;
  color: var(--white-im3wy);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 14px;
  font-size: var(--font-size-m-rvp2k);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 7px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
input:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
.roboto-light-pink-swan-12px {
  color: #b6b6b6;
  color: var(--pink-swan-cocxz);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 12px;
  font-size: var(--font-size-s-g48yb);
  font-style: normal;
  font-weight: 300;
}
.roboto-medium-dove-gray-14px {
  color: #6d6e6f;
  color: var(--dove-gray-fxtu4);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-0t6nd);
  font-size: 14px;
  font-size: var(--font-size-m-rvp2k);
  font-style: normal;
  font-weight: 500;
}
.border-1px-bon-jour {
  border: 1px solid #e0e0e0;
  border: 1px solid var(--bon-jour-4qlvz);
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183782046) */

:root {
  --font-family-roboto-condensedbold-uwsam: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-bt1jy: "Roboto", Helvetica;
  --font-size-xxxxl-8hc1m: 23px;
  --font-size-xxxl-2vebl: 20px;
  --font-size-xxl-3n8u5: 19px;
  --font-size-xl-mlvrl: 16px;
  --font-size-s-nyltl: 12px;
  --font-size-m-efxhc: 14px;
  --font-size-l-pe1wd: 15.2px;
  --white-q82y9: #ffffff;
  --pink-swan-4gkia: #b6b6b6;
  --dove-gray-13nx3: #6d6e6f;
  --bon-jour-kkfyt: #e0e0e0;
  --blue-ribbon-lj5qb: #0063e7;
  --black-vjm6f: #000000;
}
.edit-specialist-doctor {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.edit-specialist-doctor .group-93711-kfI6o0 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
}
.edit-specialist-doctor .rectangle-47173-Fu7qyp {
  background-color: #ffffff;
  background-color: var(--white-q82y9);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .group-93715-Fu7qyp {
  background-color: transparent;
  height: 424px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.edit-specialist-doctor .logo-CIxoKX {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.edit-specialist-doctor .x51-client_profile_subscription-10-M3aVii {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.edit-specialist-doctor .global-doctors-M3aVii {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-lj5qb);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 23px;
  font-size: var(--font-size-xxxxl-8hc1m);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.edit-specialist-doctor .span0-EOPPM4 {
  font-family: var(--font-family-roboto-bt1jy-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.edit-specialist-doctor .span1-EOPPM4 {
  font-size: 19px;
  font-size: var(--font-size-xxl-3n8u5);
  font-style: normal;
}
.edit-specialist-doctor .login-details-CIxoKX {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.53% - 26px);
  width: 148px;
}
.edit-specialist-doctor .hello-Ct8Itd {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 15.2px;
  font-size: var(--font-size-l-pe1wd);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.edit-specialist-doctor .mr-rakin-Ct8Itd {
  background-color: transparent;
  color: #000000;
  color: var(--black-vjm6f);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.edit-specialist-doctor .sathishrakinainqacom-Ct8Itd {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-vjm6f);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.edit-specialist-doctor .line-9-Ct8Itd {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.edit-specialist-doctor .reports-CIxoKX {
  background-color: transparent;
  bottom: 40px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.edit-specialist-doctor .reports-3c1x4w {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.edit-specialist-doctor .download-7-3c1x4w {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.edit-specialist-doctor .patient-regn-CIxoKX {
  background-color: transparent;
  bottom: calc(44.31% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.edit-specialist-doctor .patient-registration-7r1cAl {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.edit-specialist-doctor .rectangle-47175-7r1cAl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.edit-specialist-doctor .rectangle-47179-CIxoKX {
  background-color: #fff9ed;
  border-radius: 5px;
  bottom: 98px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.edit-specialist-doctor .master-CIxoKX {
  background-color: transparent;
  bottom: 106px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.edit-specialist-doctor .master-IJUCAc {
  background-color: transparent;
  color: #000000;
  color: var(--black-vjm6f);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 14px;
  font-size: var(--font-size-m-efxhc);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.edit-specialist-doctor .material-master-data-IJUCAc {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.edit-specialist-doctor .group-93714-CIxoKX {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.edit-specialist-doctor .visit-registration-DGO3Nu {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.edit-specialist-doctor .download-6-DGO3Nu {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.edit-specialist-doctor .version-kfI6o0 {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.edit-specialist-doctor .v1001-eZBUGG {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.edit-specialist-doctor .line-8-kfI6o0 {
  background-color: transparent;
  height: 1px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: 70px;
  width: 1145px;
}
.edit-specialist-doctor .onsite-doctor-medical-assistant-kfI6o0 {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 14px;
  font-size: var(--font-size-m-efxhc);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(37.95% - 77px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 204px;
}
.edit-specialist-doctor .specialist-doctors-kfI6o0 {
  background-color: transparent;
  height: 32px;
  left: calc(22.95% - 25px);
  position: absolute;
  top: 98px;
  width: 107px;
}
.edit-specialist-doctor .specialist-doctor-xQx0ub {
  background-color: transparent;
  color: #000000;
  color: var(--black-vjm6f);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 14px;
  font-size: var(--font-size-m-efxhc);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.edit-specialist-doctor .rectangle-43868-xQx0ub {
  background-color: transparent;
  bottom: 0px;
  height: 3px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 2px);
}
.edit-specialist-doctor .patient-facility-kfI6o0 {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 14px;
  font-size: var(--font-size-m-efxhc);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(46.74% - 43px);
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 93px;
}
.edit-specialist-doctor .group-93712-kfI6o0 {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.edit-specialist-doctor .rectangle-47180-rnzVgE {
  background-color: #ffffff;
  background-color: var(--white-q82y9);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .login-avatar-rnzVgE {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 134px;
}
.edit-specialist-doctor .rectangle-47177-aup68v {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-lj5qb);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .rectangle-47178-aup68v {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.edit-specialist-doctor .rakin-aup68v {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-lj5qb);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 14px;
  font-size: var(--font-size-m-efxhc);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.edit-specialist-doctor .icon-awesome-chevron-down-aup68v {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.edit-specialist-doctor .master-rnzVgE {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-vjm6f);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 59px;
}
.edit-specialist-doctor .group-93704-kfI6o0 {
  background-color: transparent;
  height: 599px;
  position: absolute;
  right: calc(37.06% - 188px);
  top: 155px;
  width: 508px;
}
.edit-specialist-doctor .rectangle-47165-sxUO8R {
  background-color: #ffffff;
  background-color: var(--white-q82y9);
  border: 0.5px solid #1010101a;
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .edit-specialist-doctor-sxUO8R {
  background-color: transparent;
  color: #000000;
  color: var(--black-vjm6f);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 16px;
  font-size: var(--font-size-xl-mlvrl);
  font-style: normal;
  font-weight: 500;
  height: 23px;
  left: 26px;
  letter-spacing: 0px;
  line-height: 16px;
  position: absolute;
  text-align: left;
  top: 20px;
  white-space: nowrap;
  width: 159px;
}
.edit-specialist-doctor .line-3-sxUO8R {
  background-color: transparent;
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 100%;
}
.edit-specialist-doctor .qdm-list-sxUO8R {
  background-color: transparent;
  left: 26px;
  position: absolute;
  top: calc(48.15% - 197px);
  width: 100%;
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: scroll;
}
.edit-specialist-doctor .listitem-7f9WOl {
  background-color: transparent;
  position: relative;
  width: calc(100% - 52px);
  height: 410px;
  margin: 0px 10px 10px 0px;
}
.edit-specialist-doctor .qdm-textinput-LAnbvV {
  background-color: transparent;
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 1px);
}
.edit-specialist-doctor .textinput-yrhkqb {
  background-color: #ffffff;
  background-color: var(--white-q82y9);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .emp-no-yrhkqb {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 48px;
}
.edit-specialist-doctor .emp-no-yrhkqb::-webkit-input-placeholder {
  color: #b6b6b699;
}
.edit-specialist-doctor .emp-no-yrhkqb::placeholder {
  color: #b6b6b699;
}
.edit-specialist-doctor .qdm-textinput-CkG3wH {
  background-color: transparent;
  bottom: calc(40% - 12px);
  height: 30px;
  left: 1px;
  position: absolute;
  width: calc(100% - 1px);
}
.edit-specialist-doctor .textinput-khgjYZ {
  background-color: #ffffff;
  background-color: var(--white-q82y9);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .email-id-khgjYZ {
  background-color: transparent;
  border: 0;
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: calc(50% - 9px);
  white-space: nowrap;
  width: 45px;
}
.edit-specialist-doctor .email-id-khgjYZ::-webkit-input-placeholder {
  color: #b6b6b699;
}
.edit-specialist-doctor .email-id-khgjYZ::placeholder {
  color: #b6b6b699;
}
.edit-specialist-doctor .qdm-textinput-chUDgh {
  background-color: transparent;
  bottom: 0px;
  height: 30px;
  left: 1px;
  position: absolute;
  width: calc(100% - 1px);
}
.edit-specialist-doctor .textinput-xDI8qF {
  background-color: #ffffff;
  background-color: var(--white-q82y9);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .enter-address-xDI8qF {
  background-color: transparent;
  border: 0;
  bottom: calc(42.44% - 8px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 79px;
}
.edit-specialist-doctor .enter-address-xDI8qF::-webkit-input-placeholder {
  color: #b6b6b699;
}
.edit-specialist-doctor .enter-address-xDI8qF::placeholder {
  color: #b6b6b699;
}
.edit-specialist-doctor .qdm-dropdown-LAnbvV {
  background-color: transparent;
  height: 30px;
  left: 1px;
  position: absolute;
  top: calc(40% - 12px);
  width: calc(100% - 3px);
}
.edit-specialist-doctor .rectangle-47161-wcKcAa {
  background-color: #ffffff;
  background-color: var(--white-q82y9);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .location-wcKcAa {
  background-color: transparent;
  bottom: calc(137.5% - 25px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 48px;
}
.edit-specialist-doctor .group-92805-wcKcAa {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.edit-specialist-doctor .icons8-expand-arrow-K7ttYQ {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.edit-specialist-doctor .qdm-dropdown-CkG3wH {
  background-color: transparent;
  height: 30px;
  left: 0px;
  position: absolute;
  top: calc(20% - 6px);
  width: calc(100% - 3px);
}
.edit-specialist-doctor .rectangle-47161-dFMrkZ {
  background-color: #ffffff;
  background-color: var(--white-q82y9);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .speciality-dFMrkZ {
  background-color: transparent;
  bottom: calc(137.5% - 25px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 52px;
}
.edit-specialist-doctor .group-92805-dFMrkZ {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.edit-specialist-doctor .icons8-expand-arrow-EKMDcJ {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.edit-specialist-doctor .qdm-textinput-wd0eFl {
  background-color: transparent;
  bottom: calc(20% - 6px);
  height: 30px;
  left: 0px;
  position: absolute;
  width: calc(100% - 1px);
}
.edit-specialist-doctor .rectangle-42910-P2kBnw {
  background-color: #ffffff;
  background-color: var(--white-q82y9);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .enter-contact-number-P2kBnw {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: calc(35.14% - 43px);
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 121px;
}
.edit-specialist-doctor .enter-contact-number-P2kBnw::-webkit-input-placeholder {
  color: #b6b6b699;
}
.edit-specialist-doctor .enter-contact-number-P2kBnw::placeholder {
  color: #b6b6b699;
}
.edit-specialist-doctor .group1234567-P2kBnw {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 88px;
}
.edit-specialist-doctor .rectangle-47161-OoFf4B {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .x91-OoFf4B {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  color: #b6b6b6;
  color: var(--pink-swan-4gkia);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 12px;
  font-size: var(--font-size-s-nyltl);
  font-style: normal;
  font-weight: 400;
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 15px;
}
.edit-specialist-doctor .group-92805-OoFf4B {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 8px;
  top: 3px;
  width: 24px;
}
.edit-specialist-doctor .icons8-expand-arrow-pxixQw {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.edit-specialist-doctor .group-93708-sxUO8R {
  background-color: transparent;
  bottom: 26px;
  height: 16px;
  left: 28px;
  position: absolute;
  width: 37px;
}
.edit-specialist-doctor .back-FQjxLy {
  background-color: transparent;
  color: #000000d1;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 12px;
  font-size: var(--font-size-s-nyltl);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 29px;
}
.edit-specialist-doctor .path-734-FQjxLy {
  background-color: transparent;
  bottom: calc(38.94% - 3px);
  height: 7px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  width: 7px;
}
.edit-specialist-doctor .qdm-button-sxUO8R {
  background-color: transparent;
  bottom: 20px;
  height: 26px;
  position: absolute;
  right: 27px;
  width: 84px;
}
.edit-specialist-doctor .rectangle-45330-2MqPqf {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-lj5qb);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.edit-specialist-doctor .submit-2MqPqf {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-lj5qb);
  color: #ffffff;
  color: var(--white-q82y9);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 14px;
  font-size: var(--font-size-m-efxhc);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 7px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
input:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
.roboto-light-pink-swan-12px {
  color: #b6b6b6;
  color: var(--pink-swan-4gkia);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 12px;
  font-size: var(--font-size-s-nyltl);
  font-style: normal;
  font-weight: 300;
}
.roboto-medium-dove-gray-14px {
  color: #6d6e6f;
  color: var(--dove-gray-13nx3);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-bt1jy);
  font-size: 14px;
  font-size: var(--font-size-m-efxhc);
  font-style: normal;
  font-weight: 500;
}
.border-1px-bon-jour {
  border: 1px solid #e0e0e0;
  border: 1px solid var(--bon-jour-kkfyt);
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183772619) */

:root {
  --font-family-roboto-condensedbold-0lvmf: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-t2qam: "Roboto", Helvetica;
  --font-size-xxxxxl-4vwag: 23px;
  --font-size-xxxxl-jsdv8: 20px;
  --font-size-xxxl-eq4hl: 19px;
  --font-size-xxl-z3tn3: 16px;
  --font-size-xl-qyqhm: 15.2px;
  --font-size-m-i3cj4: 12px;
  --font-size-l-gpr8w: 14px;
  --white-uia62: #ffffff;
  --pink-swan-pvaum: #b6b6b6;
  --dove-gray-lwgpu: #6d6e6f;
  --bon-jour-ii9sn: #e0e0e0;
  --blue-ribbon-1tlmg: #0063e7;
  --black-vvta5: #000000;
}
.add-specialist-doctor {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.add-specialist-doctor .group-93704-B47n0V {
  background-color: transparent;
  height: 586px;
  position: absolute;
  right: calc(37.06% - 188px);
  top: 156px;
  width: 508px;
}
.add-specialist-doctor .rectangle-47165-xPtKvX {
  background-color: #ffffff;
  background-color: var(--white-uia62);
  border: 0.5px solid #1010101a;
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .add-specialist-doctor-xPtKvX {
  background-color: transparent;
  color: #000000;
  color: var(--black-vvta5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 16px;
  font-size: var(--font-size-xxl-z3tn3);
  font-style: normal;
  font-weight: 500;
  height: 23px;
  left: 26px;
  letter-spacing: 0px;
  line-height: 16px;
  position: absolute;
  text-align: left;
  top: 18px;
  white-space: nowrap;
  width: 157px;
}
.add-specialist-doctor .line-3-xPtKvX {
  background-color: transparent;
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 100%;
}
.add-specialist-doctor .qdm-textinput-xPtKvX {
  background-color: transparent;
  height: 30px;
  left: 26px;
  position: absolute;
  top: calc(15.84% - 5px);
  width: calc(100% - 53px);
}
.add-specialist-doctor .textinput-ieZzMe {
  background-color: #ffffff;
  background-color: var(--white-uia62);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .enter-full-name-ieZzMe {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 85px;
}
.add-specialist-doctor .enter-full-name-ieZzMe::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-specialist-doctor .enter-full-name-ieZzMe::placeholder {
  color: #b6b6b699;
}
.add-specialist-doctor .qdm-textinput-rC6gw6 {
  background-color: transparent;
  bottom: calc(46.31% - 14px);
  height: 30px;
  left: 26px;
  position: absolute;
  width: calc(100% - 53px);
}
.add-specialist-doctor .textinput-VEHLVE {
  background-color: #ffffff;
  background-color: var(--white-uia62);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .enter-doctor-email-VEHLVE {
  background-color: transparent;
  border: 0;
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: calc(50% - 9px);
  white-space: nowrap;
  width: 105px;
}
.add-specialist-doctor .enter-doctor-email-VEHLVE::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-specialist-doctor .enter-doctor-email-VEHLVE::placeholder {
  color: #b6b6b699;
}
.add-specialist-doctor .qdm-textinput-acJ7E3 {
  background-color: transparent;
  bottom: calc(21.08% - 6px);
  height: 30px;
  left: 26px;
  position: absolute;
  width: calc(100% - 53px);
}
.add-specialist-doctor .textinput-fjxdnD {
  background-color: #ffffff;
  background-color: var(--white-uia62);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .enter-address-fjxdnD {
  background-color: transparent;
  border: 0;
  bottom: calc(42.44% - 8px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 79px;
}
.add-specialist-doctor .enter-address-fjxdnD::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-specialist-doctor .enter-address-fjxdnD::placeholder {
  color: #b6b6b699;
}
.add-specialist-doctor .qdm-dropdown-xPtKvX {
  background-color: transparent;
  height: 30px;
  left: 26px;
  position: absolute;
  top: calc(41.07% - 12px);
  width: calc(100% - 55px);
}
.add-specialist-doctor .rectangle-47161-g28Pna {
  background-color: #ffffff;
  background-color: var(--white-uia62);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .select-g28Pna {
  background-color: transparent;
  bottom: calc(137.5% - 25px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 34px;
}
.add-specialist-doctor .group-92805-g28Pna {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.add-specialist-doctor .icons8-expand-arrow-LYbhj0 {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.add-specialist-doctor .qdm-dropdown-rC6gw6 {
  background-color: transparent;
  height: 30px;
  left: 26px;
  position: absolute;
  top: calc(28.46% - 9px);
  width: calc(100% - 55px);
}
.add-specialist-doctor .rectangle-47161-fxdYiq {
  background-color: #ffffff;
  background-color: var(--white-uia62);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .select-fxdYiq {
  background-color: transparent;
  bottom: calc(137.5% - 25px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 34px;
}
.add-specialist-doctor .group-92805-fxdYiq {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 7px;
  top: 3px;
  width: 24px;
}
.add-specialist-doctor .icons8-expand-arrow-Mp9uuJ {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.add-specialist-doctor .qdm-textinput-4sjKsh {
  background-color: transparent;
  bottom: calc(33.7% - 10px);
  height: 30px;
  left: 26px;
  position: absolute;
  width: calc(100% - 53px);
}
.add-specialist-doctor .rectangle-42910-EZstcs {
  background-color: #ffffff;
  background-color: var(--white-uia62);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .enter-contact-number-EZstcs {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: calc(35.14% - 43px);
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 121px;
}
.add-specialist-doctor .enter-contact-number-EZstcs::-webkit-input-placeholder {
  color: #b6b6b699;
}
.add-specialist-doctor .enter-contact-number-EZstcs::placeholder {
  color: #b6b6b699;
}
.add-specialist-doctor .group1234567-EZstcs {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 88px;
}
.add-specialist-doctor .rectangle-47161-JV3hxq {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .x91-JV3hxq {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  color: #b6b6b6;
  color: var(--pink-swan-pvaum);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 12px;
  font-size: var(--font-size-m-i3cj4);
  font-style: normal;
  font-weight: 400;
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 15px;
}
.add-specialist-doctor .group-92805-JV3hxq {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 8px;
  top: 3px;
  width: 24px;
}
.add-specialist-doctor .icons8-expand-arrow-Q2hIz0 {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.add-specialist-doctor .group-93708-xPtKvX {
  background-color: transparent;
  bottom: 46px;
  height: 16px;
  left: 26px;
  position: absolute;
  width: 37px;
}
.add-specialist-doctor .back-da45DN {
  background-color: transparent;
  color: #000000d1;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 12px;
  font-size: var(--font-size-m-i3cj4);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 29px;
}
.add-specialist-doctor .path-734-da45DN {
  background-color: transparent;
  bottom: calc(38.94% - 3px);
  height: 7px;
  left: -1px;
  object-fit: cover;
  position: absolute;
  width: 7px;
}
.add-specialist-doctor .qdm-button-xPtKvX {
  background-color: transparent;
  bottom: 40px;
  height: 26px;
  position: absolute;
  right: 27px;
  width: 84px;
}
.add-specialist-doctor .rectangle-45330-UYmVae {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-1tlmg);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .submit-UYmVae {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-1tlmg);
  color: #ffffff;
  color: var(--white-uia62);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 14px;
  font-size: var(--font-size-l-gpr8w);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 7px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
.add-specialist-doctor .group-93711-B47n0V {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
  
}
.add-specialist-doctor .rectangle-47173-Eqnjc7 {
  background-color: #ffffff;
  background-color: var(--white-uia62);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-right: 1px solid lightgrey;
}
.add-specialist-doctor .group-93715-Eqnjc7 {
  background-color: transparent;
  height: 424px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.add-specialist-doctor .logo-f4xRiC {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.add-specialist-doctor .x51-client_profile_subscription-10-75rJ9e {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.add-specialist-doctor .global-doctors-75rJ9e {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-1tlmg);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 23px;
  font-size: var(--font-size-xxxxxl-4vwag);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.add-specialist-doctor .span0-YH2Lpv {
  font-family: var(--font-family-roboto-t2qam-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.add-specialist-doctor .span1-YH2Lpv {
  font-size: 19px;
  font-size: var(--font-size-xxxl-eq4hl);
  font-style: normal;
}
.add-specialist-doctor .login-details-f4xRiC {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.53% - 26px);
  width: 148px;
}
.add-specialist-doctor .hello-MpNhwp {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 15.2px;
  font-size: var(--font-size-xl-qyqhm);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.add-specialist-doctor .mr-rakin-MpNhwp {
  background-color: transparent;
  color: #000000;
  color: var(--black-vvta5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.add-specialist-doctor .sathishrakinainqacom-MpNhwp {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-vvta5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.add-specialist-doctor .line-9-MpNhwp {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.add-specialist-doctor .reports-f4xRiC {
  background-color: transparent;
  bottom: 40px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.add-specialist-doctor .reports-PRhm9N {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.add-specialist-doctor .download-7-PRhm9N {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.add-specialist-doctor .patient-regn-f4xRiC {
  background-color: transparent;
  bottom: calc(44.31% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.add-specialist-doctor .patient-registration-66xEsl {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.add-specialist-doctor .rectangle-47175-66xEsl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.add-specialist-doctor .rectangle-47179-f4xRiC {
  background-color: #ece3d3;
  border-radius: 5px;
  bottom: 98px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.add-specialist-doctor .master-f4xRiC {
  background-color: transparent;
  bottom: 106px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.add-specialist-doctor .master-l5DuEx {
  background-color: transparent;
  color: #000000;
  color: var(--black-vvta5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 14px;
  font-size: var(--font-size-l-gpr8w);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.add-specialist-doctor .material-master-data-l5DuEx {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.add-specialist-doctor .group-93714-f4xRiC {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.add-specialist-doctor .visit-registration-MjGdxM {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.add-specialist-doctor .download-6-MjGdxM {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.add-specialist-doctor .version-B47n0V {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.add-specialist-doctor .v1001-uvQXEm {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.add-specialist-doctor .line-8-B47n0V {
  background-color: transparent;
  height: 1px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: 70px;
  width: 1145px;
}
.add-specialist-doctor .onsite-doctor-medical-assistant-B47n0V {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 14px;
  font-size: var(--font-size-l-gpr8w);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(37.95% - 77px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 204px;
}
.add-specialist-doctor .specialist-doctors-B47n0V {
  background-color: transparent;
  height: 32px;
  left: calc(22.95% - 25px);
  position: absolute;
  top: 98px;
  width: 107px;
}
.add-specialist-doctor .specialist-doctor-r7B79V {
  background-color: transparent;
  color: #000000;
  color: var(--black-vvta5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 14px;
  font-size: var(--font-size-l-gpr8w);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.add-specialist-doctor .rectangle-43868-r7B79V {
  background-color: transparent;
  bottom: 0px;
  height: 3px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 2px);
}
.add-specialist-doctor .patient-facility-B47n0V {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 14px;
  font-size: var(--font-size-l-gpr8w);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(46.74% - 43px);
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 93px;
}
.add-specialist-doctor .group-93712-B47n0V {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.add-specialist-doctor .rectangle-47180-iZeJ8x {
  background-color: #ffffff;
  background-color: var(--white-uia62);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}
.add-specialist-doctor .login-avatar-iZeJ8x {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 134px;
}
.add-specialist-doctor .rectangle-47177-xqwBnA {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-1tlmg);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.add-specialist-doctor .rectangle-47178-xqwBnA {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.add-specialist-doctor .rakin-xqwBnA {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-1tlmg);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 14px;
  font-size: var(--font-size-l-gpr8w);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.add-specialist-doctor .icon-awesome-chevron-down-xqwBnA {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.add-specialist-doctor .master-iZeJ8x {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-vvta5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 59px;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
input:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
.roboto-light-pink-swan-12px {
  color: #b6b6b6;
  color: var(--pink-swan-pvaum);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 12px;
  font-size: var(--font-size-m-i3cj4);
  font-style: normal;
  font-weight: 300;
}
.roboto-medium-dove-gray-14px {
  color: #6d6e6f;
  color: var(--dove-gray-lwgpu);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-t2qam);
  font-size: 14px;
  font-size: var(--font-size-l-gpr8w);
  font-style: normal;
  font-weight: 500;
}
.border-1px-bon-jour {
  border: 1px solid #e0e0e0;
  border: 1px solid var(--bon-jour-ii9sn);
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183801486) */

:root {
  --font-family-roboto-condensedbold-ikuek: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-ssakl: "Roboto", Helvetica;
  --font-size-xxxl-wyh29: 23px;
  --font-size-xxl-ogsuh: 20px;
  --font-size-xs-5d1cc: 11px;
  --font-size-xl-3niic: 19px;
  --font-size-s-belvd: 13px;
  --font-size-m-eo6af: 14px;
  --font-size-l-py10z: 15.2px;
  --woodsmoke-cwgga: #101010;
  --white-1nxnf: #ffffff;
  --dove-gray-gsby9: #6d6e6f;
  --boulder-0kybc: #787878;
  --blue-ribbon-ehsi9: #0063e7;
  --black-qhws8: #000000;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
* {
  box-sizing: border-box;
}
.master-patient-facility {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.master-patient-facility .specialist-doctor-5vmi5X {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 14px;
  font-size: var(--font-size-m-eo6af);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(22.97% - 25px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 108px;
}
.master-patient-facility .qdm-button-5vmi5X {
  background-color: transparent;
  height: 26px;
  position: absolute;
  right: 16px;
  top: 94px;
  width: 84px;
}
.master-patient-facility .rectangle-45330-rUDBvK {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-ehsi9);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-patient-facility .add-new-rUDBvK {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-ehsi9);
  color: #ffffff;
  color: var(--white-1nxnf);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 14px;
  font-size: var(--font-size-m-eo6af);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 6px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
.master-patient-facility .onsite-doctor-medical-assistant-5vmi5X {
  background-color: transparent;
  color: #6f6f6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 14px;
  font-size: var(--font-size-m-eo6af);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(37.95% - 77px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 204px;
}
.master-patient-facility .group-93717-5vmi5X {
  background-color: transparent;
  height: calc(100% - 146px);
  left: 234px;
  position: absolute;
  top: 141px;
  width: calc(100% - 245px);
}
.master-patient-facility .rectangle-47148-hQXPyx {
  background-color: #ffffff;
  background-color: var(--white-1nxnf);
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 3px);
}
.master-patient-facility .group-93702-hQXPyx {
  background-color: transparent;
  height: 2.9%;
  left: 60px;
  position: absolute;
  top: 18px;
  width: calc(100% - 153px);
}
.master-patient-facility .facility-name-zQ1ECm {
  background-color: transparent;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 87px;
}
.master-patient-facility .address-zQ1ECm {
  background-color: transparent;
  height: calc(100% + 2px);
  left: calc(26.27% - 14px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 54px;
}
.master-patient-facility .state-zQ1ECm {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(48.76% - 17px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 35px;
}
.master-patient-facility .city-zQ1ECm {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(26.45% - 7px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 26px;
}
.master-patient-facility .postal-code-zQ1ECm {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 76px;
}
.master-patient-facility .qdm-list-hQXPyx {
  background-color: transparent;
  left: 0px;
  position: absolute;
  top: 2px;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: scroll;
}
.master-patient-facility .listitem-Mi250Z {
  background-color: transparent;
  position: relative;
  width: calc(100% - 133px);
  height: 32px;
  margin: 0px 10px 10px 0px;
}
.master-patient-facility .tanyacare-9x4qpe {
  background-color: transparent;
  height: 17px;
  left: 15px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 50px;
}
.master-patient-facility .chennai-9x4qpe {
  background-color: transparent;
  height: 17px;
  left: calc(26.6% - 30px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 42px;
}
.master-patient-facility .line-4-9x4qpe {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
.master-patient-facility .tamilnadu-9x4qpe {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(49.14% - 26px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 53px;
}
.master-patient-facility .chennai-wswQxM {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(26.6% - 11px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 42px;
}
.master-patient-facility .x600024-9x4qpe {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: 34px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 39px;
}
.master-patient-facility .line-2-hQXPyx {
  background-color: transparent;
  height: 1px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 47px;
  width: calc(100% - 3px);
}
.master-patient-facility .patient-facility-5vmi5X {
  background-color: transparent;
  height: 30px;
  position: absolute;
  right: calc(46.74% - 43px);
  top: 99px;
  width: 93px;
}
.master-patient-facility .patient-facility-UADC48 {
  background-color: transparent;
  color: #000000;
  color: var(--black-qhws8);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 14px;
  font-size: var(--font-size-m-eo6af);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.master-patient-facility .rectangle-47182-UADC48 {
  background-color: transparent;
  bottom: 0px;
  height: 3px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 6px);
}
.master-patient-facility .group-93711-5vmi5X {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
}
.master-patient-facility .rectangle-47173-8Gxuvg {
  background-color: #ffffff;
  background-color: var(--white-1nxnf);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-right: 1px solid lightgrey;
}
.master-patient-facility .group-93715-8Gxuvg {
  background-color: transparent;
  height: 424px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.master-patient-facility .logo-QJDPFy {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.master-patient-facility .x51-client_profile_subscription-10-JefTXo {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.master-patient-facility .global-doctors-JefTXo {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-ehsi9);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 23px;
  font-size: var(--font-size-xxxl-wyh29);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.master-patient-facility .span0-MMDxyo {
  font-family: var(--font-family-roboto-ssakl-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.master-patient-facility .span1-MMDxyo {
  font-size: 19px;
  font-size: var(--font-size-xl-3niic);
  font-style: normal;
}
.master-patient-facility .login-details-QJDPFy {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.53% - 26px);
  width: 148px;
}
.master-patient-facility .hello-7Q2FDr {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 15.2px;
  font-size: var(--font-size-l-py10z);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.master-patient-facility .mr-rakin-7Q2FDr {
  background-color: transparent;
  color: #000000;
  color: var(--black-qhws8);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.master-patient-facility .sathishrakinainqacom-7Q2FDr {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-qhws8);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 13px;
  font-size: var(--font-size-s-belvd);
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.master-patient-facility .line-9-7Q2FDr {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.master-patient-facility .reports-QJDPFy {
  background-color: transparent;
  bottom: 40px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.master-patient-facility .reports-p2FbPI {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.master-patient-facility .download-7-p2FbPI {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.master-patient-facility .patient-regn-QJDPFy {
  background-color: transparent;
  bottom: calc(44.31% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.master-patient-facility .patient-registration-kvA40r {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.master-patient-facility .rectangle-47175-kvA40r {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.master-patient-facility .rectangle-47179-QJDPFy {
  background-color: #ece3d3;
  border-radius: 5px;
  bottom: 98px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.master-patient-facility .master-QJDPFy {
  background-color: transparent;
  bottom: 106px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.master-patient-facility .master-5173Yj {
  background-color: transparent;
  color: #000000;
  color: var(--black-qhws8);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 14px;
  font-size: var(--font-size-m-eo6af);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.master-patient-facility .material-master-data-5173Yj {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.master-patient-facility .group-93714-QJDPFy {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.master-patient-facility .visit-registration-pqxGPt {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.master-patient-facility .download-6-pqxGPt {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.master-patient-facility .version-5vmi5X {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.master-patient-facility .v1001-UmVqgD {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.master-patient-facility .group-93712-5vmi5X {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
  border-bottom: 1px solid lightgrey;
}
.master-patient-facility .rectangle-47180-V34HkV {
  background-color: #ffffff;
  background-color: var(--white-1nxnf);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}
.master-patient-facility .login-avatar-V34HkV {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 134px;
}
.master-patient-facility .rectangle-47177-7gfhGx {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-ehsi9);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-patient-facility .rectangle-47178-7gfhGx {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.master-patient-facility .rakin-7gfhGx {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-ehsi9);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 14px;
  font-size: var(--font-size-m-eo6af);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.master-patient-facility .icon-awesome-chevron-down-7gfhGx {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.master-patient-facility .master-V34HkV {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-qhws8);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 59px;
}
.roboto-medium-woodsmoke-14px {
  color: #101010;
  color: var(--woodsmoke-cwgga);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 14px;
  font-size: var(--font-size-m-eo6af);
  font-style: normal;
  font-weight: 500;
}
.roboto-normal-boulder-11px {
  color: #787878;
  color: var(--boulder-0kybc);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 11px;
  font-size: var(--font-size-xs-5d1cc);
  font-style: normal;
  font-weight: 400;
}
.roboto-medium-dove-gray-14px {
  color: #6d6e6f;
  color: var(--dove-gray-gsby9);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-ssakl);
  font-size: 14px;
  font-size: var(--font-size-m-eo6af);
  font-style: normal;
  font-weight: 500;
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770657) */
:root {
  --font-family-roboto-condensedbold-r1mtz: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-mougt: "Roboto", Helvetica;
  --font-size-xxxl-iwy5g: 23px;
  --font-size-xxl-rmhwp: 20px;
  --font-size-xs-ioyei: 11px;
  --font-size-xl-a7pnu: 19px;
  --font-size-s-gylfq: 13px;
  --font-size-m-glqak: 14px;
  --font-size-l-s2fot: 15.2px;
  --woodsmoke-2rakn: #101010;
  --white-jecjj: #ffffff;
  --dove-gray-2-pkq9x: #6d6e6f;
  --dove-gray-xy9hl: #6f6f6f;
  --blue-ribbon-66oy0: #0063e7;
  --black-y248m: #000000;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
* {
  box-sizing: border-box;
}
.master-onsite-doctor {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.master-onsite-doctor .specialist-doctor-34YBJ2 {
  background-color: transparent;
  color: #6f6f6f;
  color: var(--dove-gray-xy9hl);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 14px;
  font-size: var(--font-size-m-glqak);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(22.97% - 25px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 108px;
}
.master-onsite-doctor .patient-facility-34YBJ2 {
  background-color: transparent;
  color: #6f6f6f;
  color: var(--dove-gray-xy9hl);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 14px;
  font-size: var(--font-size-m-glqak);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(46.74% - 43px);
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 93px;
}
.master-onsite-doctor .qdm-button-34YBJ2 {
  background-color: transparent;
  height: 26px;
  position: absolute;
  right: 14px;
  top: 96px;
  width: 84px;
}
.master-onsite-doctor .rectangle-45330-TxYSuY {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-66oy0);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-onsite-doctor .add-new-TxYSuY {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-66oy0);
  color: #ffffff;
  color: var(--white-jecjj);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 14px;
  font-size: var(--font-size-m-glqak);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 6px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
.master-onsite-doctor .onsite-doctor-34YBJ2 {
  background-color: transparent;
  height: 30px;
  left: calc(37.95% - 77px);
  position: absolute;
  top: 99px;
  width: 204px;
}
.master-onsite-doctor .onsite-doctor-medical-assistant-DpGfc4 {
  background-color: transparent;
  color: #000000;
  color: var(--black-y248m);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 14px;
  font-size: var(--font-size-m-glqak);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.master-onsite-doctor .rectangle-47181-DpGfc4 {
  background-color: transparent;
  bottom: 0px;
  height: 3px;
  left: calc(46.48% - 63px);
  object-fit: cover;
  position: absolute;
  width: 135px;
}
.master-onsite-doctor .group-93711-34YBJ2 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
  border-right: 1px solid lightgrey;
}
.master-onsite-doctor .rectangle-47173-SdQL2q {
  background-color: #ffffff;
  background-color: var(--white-jecjj);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-onsite-doctor .group-93715-SdQL2q {
  background-color: transparent;
  height: 424px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.master-onsite-doctor .logo-4qQjuh {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.master-onsite-doctor .x51-client_profile_subscription-10-Xgp2pp {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.master-onsite-doctor .global-doctors-Xgp2pp {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-66oy0);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 23px;
  font-size: var(--font-size-xxxl-iwy5g);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.master-onsite-doctor .span0-qAzcIm {
  font-family: var(--font-family-roboto-mougt-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.master-onsite-doctor .span1-qAzcIm {
  font-size: 19px;
  font-size: var(--font-size-xl-a7pnu);
  font-style: normal;
}
.master-onsite-doctor .login-details-4qQjuh {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.53% - 26px);
  width: 148px;
}
.master-onsite-doctor .hello-bIS0dc {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 15.2px;
  font-size: var(--font-size-l-s2fot);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.master-onsite-doctor .mr-rakin-bIS0dc {
  background-color: transparent;
  color: #000000;
  color: var(--black-y248m);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.master-onsite-doctor .sathishrakinainqacom-bIS0dc {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-y248m);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 13px;
  font-size: var(--font-size-s-gylfq);
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.master-onsite-doctor .line-9-bIS0dc {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.master-onsite-doctor .reports-4qQjuh {
  background-color: transparent;
  bottom: 40px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.master-onsite-doctor .reports-wKwQMh {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.master-onsite-doctor .download-7-wKwQMh {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.master-onsite-doctor .patient-regn-4qQjuh {
  background-color: transparent;
  bottom: calc(44.31% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.master-onsite-doctor .patient-registration-xlxAdf {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.master-onsite-doctor .rectangle-47175-xlxAdf {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.master-onsite-doctor .rectangle-47179-4qQjuh {
  background-color: #ece3d3;
  border-radius: 5px;
  bottom: 98px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.master-onsite-doctor .master-4qQjuh {
  background-color: transparent;
  bottom: 106px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.master-onsite-doctor .master-nerYZy {
  background-color: transparent;
  color: #000000;
  color: var(--black-y248m);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 14px;
  font-size: var(--font-size-m-glqak);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.master-onsite-doctor .material-master-data-nerYZy {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.master-onsite-doctor .group-93714-4qQjuh {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.master-onsite-doctor .visit-registration-JA0VlI {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.master-onsite-doctor .download-6-JA0VlI {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.master-onsite-doctor .version-34YBJ2 {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.master-onsite-doctor .v1001-nN4wSn {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.master-onsite-doctor .group-93712-34YBJ2 {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.master-onsite-doctor .rectangle-47180-TyQF2r {
  background-color: #ffffff;
  background-color: var(--white-jecjj);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-bottom:1px solid lightgrey
}
.master-onsite-doctor .login-avatar-TyQF2r {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 134px;
}
.master-onsite-doctor .rectangle-47177-ggwQMY {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-66oy0);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-onsite-doctor .rectangle-47178-ggwQMY {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.master-onsite-doctor .rakin-ggwQMY {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-66oy0);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 14px;
  font-size: var(--font-size-m-glqak);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.master-onsite-doctor .icon-awesome-chevron-down-ggwQMY {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.master-onsite-doctor .master-TyQF2r {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-y248m);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 59px;
}
.master-onsite-doctor .group-93713-34YBJ2 {
  background-color: transparent;
  height: calc(100% - 146px);
  left: 234px;
  position: absolute;
  top: 141px;
  width: calc(100% - 245px);
}
.master-onsite-doctor .rectangle-47148-o1ODmP {
  background-color: #ffffff;
  background-color: var(--white-jecjj);
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-onsite-doctor .path-1-o1ODmP {
  background-color: transparent;
  height: 1px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 48px;
  width: 100%;
}
.master-onsite-doctor .group-93702-o1ODmP {
  background-color: transparent;
  height: 19px;
  left: 59px;
  position: absolute;
  top: 15px;
  width: calc(100% - 111px);
}
.master-onsite-doctor .name-t5xWAp {
  background-color: transparent;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 39px;
}
.master-onsite-doctor .speciality-t5xWAp {
  background-color: transparent;
  height: calc(100% + 2px);
  left: calc(16.09% - 10px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 63px;
}
.master-onsite-doctor .location-t5xWAp {
  background-color: transparent;
  height: calc(100% + 2px);
  left: calc(32.14% - 18px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 56px;
}
.master-onsite-doctor .email-t5xWAp {
  background-color: transparent;
  height: calc(100% + 2px);
  left: calc(48.89% - 18px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 37px;
}
.master-onsite-doctor .address-t5xWAp {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(34.05% - 18px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 54px;
}
.master-onsite-doctor .mobile-no-t5xWAp {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(16.73% - 11px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 66px;
}
.master-onsite-doctor .actions-t5xWAp {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 50px;
}
.master-onsite-doctor .qdm-list-o1ODmP {
  background-color: transparent;
  /* left: 27px; */
  position: absolute;
  top: 2px;
  width: 100%;
  height: 96vh;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
}
.master-onsite-doctor .listitem-QxOk3q {
  background-color: transparent;
  position: relative;
  width: calc(100% - 54px);
  height: 32px;
  margin: 0px 10px 10px 0px;
}
.master-onsite-doctor .group-93716-9ROphj {
  background-color: transparent;
  height: 12px;
  position: absolute;
  right: 32px;
  top: calc(27.89% - 3px);
  width: 37px;
}
.master-onsite-doctor .icon-material-delete-hNcsxv {
  background-color: transparent;
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 1px;
  top: 0px;
  width: 9px;
}
.master-onsite-doctor .icon-material-edit-hNcsxv {
  background-color: transparent;
  height: 100%;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 12px;
}
.master-onsite-doctor .administrator-9ROphj {
  background-color: transparent;
  height: 17px;
  left: 36px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 4px;
  white-space: nowrap;
  width: 69px;
}
.master-onsite-doctor .cardiology-9ROphj {
  background-color: transparent;
  height: 17px;
  left: calc(18.06% - 10px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 4px;
  white-space: nowrap;
  width: 54px;
}
.master-onsite-doctor .malaysia-9ROphj {
  background-color: transparent;
  height: 17px;
  left: calc(33.44% - 15px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 4px;
  white-space: nowrap;
  width: 46px;
}
.master-onsite-doctor .adminainqacom-9ROphj {
  background-color: transparent;
  height: 17px;
  left: calc(49.56% - 47px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  /* white-space: nowrap; */
  width: 142px;
  line-break:anywhere;
}
.master-onsite-doctor .malaysia-0HEekk {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(34.81% - 16px);
  text-align: left;
  top: 4px;
  white-space: nowrap;
  width: 60px;
}
.master-onsite-doctor .x1234567890-9ROphj {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(18.4% - 12px);
  text-align: left;
  top: 4px;
  white-space: nowrap;
  width: 64px;
}
.master-onsite-doctor .line-3-9ROphj {
  background-color: transparent;
  height: 3.08%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 32px;
  width: 100%;
}
.roboto-medium-woodsmoke-14px {
  color: #101010;
  color: var(--woodsmoke-2rakn);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 14px;
  font-size: var(--font-size-m-glqak);
  font-style: normal;
  font-weight: 500;
}
.roboto-normal-dove-gray-11px {
  color: #6f6f6f;
  color: var(--dove-gray-xy9hl);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 11px;
  font-size: var(--font-size-xs-ioyei);
  font-style: normal;
  font-weight: 400;
}
.roboto-medium-dove-gray-14px {
  color: var(--dove-gray-xy9hl-2);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-mougt);
  font-size: 14px;
  font-size: var(--font-size-m-glqak);
  font-style: normal;
  font-weight: 500;
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770491) */

:root {
  --font-family-roboto-condensedbold-udfey: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-4h6f3: "Roboto", Helvetica;
  --font-size-xxxl-yw5dc: 23px;
  --font-size-xxl-tv7xx: 20px;
  --font-size-xs-wqjqx: 11px;
  --font-size-xl-odfem: 19px;
  --font-size-s-23h3v: 13px;
  --font-size-m-f3fkn: 14px;
  --font-size-l-pukli: 15.2px;
  --woodsmoke-b1vj9: #101010;
  --white-ppqco: #ffffff;
  --dove-gray-2-qurgm: #6d6e6f;
  --dove-gray-pn67j: #6f6f6f;
  --blue-ribbon-denhp: #0063e7;
  --black-v6kz6: #000000;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
.hidden,
.hidden * {
  pointer-events: none;
  visibility: hidden;
}
* {
  box-sizing: border-box;
}
.master-specialist-doctor {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.master-specialist-doctor .line-8-sKqQC0 {
  background-color: transparent;
  height: 1px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: 70px;
  width: 1145px;
}
.master-specialist-doctor .onsite-doctor-medical-assistant-sKqQC0 {
  background-color: transparent;
  color: #6f6f6f;
  color: var(--dove-gray-pn67j);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 14px;
  font-size: var(--font-size-m-f3fkn);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: calc(37.95% - 77px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 204px;
}
.master-specialist-doctor .specialist-doctors-sKqQC0 {
  background-color: transparent;
  height: 32px;
  left: calc(22.95% - 25px);
  position: absolute;
  top: 98px;
  width: 107px;
}
.master-specialist-doctor .specialist-doctor-Tc9Qm5 {
  background-color: transparent;
  color: #000000;
  color: var(--black-v6kz6);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 14px;
  font-size: var(--font-size-m-f3fkn);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.master-specialist-doctor .rectangle-43868-Tc9Qm5 {
  background-color: transparent;
  bottom: 0px;
  height: 3px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 2px);
}
.master-specialist-doctor .patient-facility-sKqQC0 {
  background-color: transparent;
  color: #6f6f6f;
  color: var(--dove-gray-pn67j);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 14px;
  font-size: var(--font-size-m-f3fkn);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(46.74% - 43px);
  text-align: left;
  top: 102px;
  white-space: nowrap;
  width: 93px;
}
.master-specialist-doctor .qdm-button-sKqQC0 {
  background-color: transparent;
  height: 26px;
  position: absolute;
  right: 11px;
  top: 96px;
  width: 84px;
}
.master-specialist-doctor .rectangle-45330-NwfBd7 {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-denhp);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-specialist-doctor .add-new-NwfBd7 {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-denhp);
  color: #ffffff;
  color: var(--white-ppqco);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 14px;
  font-size: var(--font-size-m-f3fkn);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 6px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
.master-specialist-doctor .group-93711-sKqQC0 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
  border-right: 1px solid lightgrey;
}
.master-specialist-doctor .rectangle-47173-9HtIAx {
  background-color: #ffffff;
  background-color: var(--white-ppqco);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-specialist-doctor .group-93715-9HtIAx {
  background-color: transparent;
  height: 424px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.master-specialist-doctor .logo-cEKuIH {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.master-specialist-doctor .x51-client_profile_subscription-10-gjZCAb {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.master-specialist-doctor .global-doctors-gjZCAb {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-denhp);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 23px;
  font-size: var(--font-size-xxxl-yw5dc);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.master-specialist-doctor .span0-1FAQAl {
  font-family: var(--font-family-roboto-4h6f3-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.master-specialist-doctor .span1-1FAQAl {
  font-size: 19px;
  font-size: var(--font-size-xl-odfem);
  font-style: normal;
}
.master-specialist-doctor .login-details-cEKuIH {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.53% - 26px);
  width: 148px;
}
.master-specialist-doctor .hello-kBWzxf {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 15.2px;
  font-size: var(--font-size-l-pukli);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.master-specialist-doctor .mr-rakin-kBWzxf {
  background-color: transparent;
  color: #000000;
  color: var(--black-v6kz6);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.master-specialist-doctor .sathishrakinainqacom-kBWzxf {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-v6kz6);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 13px;
  font-size: var(--font-size-s-23h3v);
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.master-specialist-doctor .line-9-kBWzxf {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.master-specialist-doctor .reports-cEKuIH {
  background-color: transparent;
  bottom: 40px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.master-specialist-doctor .reports-9UMiQm {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.master-specialist-doctor .download-7-9UMiQm {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.master-specialist-doctor .patient-regn-cEKuIH {
  background-color: transparent;
  bottom: calc(44.31% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.master-specialist-doctor .patient-registration-Bud2tg {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.master-specialist-doctor .rectangle-47175-Bud2tg {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.master-specialist-doctor .rectangle-47179-cEKuIH {
  background-color: #ece3d3;
  border-radius: 5px;
  bottom: 98px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.master-specialist-doctor .master-cEKuIH {
  background-color: transparent;
  bottom: 106px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.master-specialist-doctor .master-dxvZzg {
  background-color: transparent;
  color: #000000;
  color: var(--black-v6kz6);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 14px;
  font-size: var(--font-size-m-f3fkn);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.master-specialist-doctor .material-master-data-dxvZzg {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.master-specialist-doctor .group-93714-cEKuIH {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.master-specialist-doctor .visit-registration-JxDQui {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.master-specialist-doctor .download-6-JxDQui {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.master-specialist-doctor .group-93712-sKqQC0 {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
  border-bottom: 1px solid lightgrey!important;
}
.master-specialist-doctor .rectangle-47180-o2SaxQ {
  background-color: #ffffff;
  background-color: var(--white-ppqco);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}
.master-specialist-doctor .login-avatar-o2SaxQ {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 134px;
}
.master-specialist-doctor .rectangle-47177-3hr33c {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-denhp);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-specialist-doctor .rectangle-47178-3hr33c {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.master-specialist-doctor .rakin-3hr33c {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-denhp);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 14px;
  font-size: var(--font-size-m-f3fkn);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.master-specialist-doctor .icon-awesome-chevron-down-3hr33c {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.master-specialist-doctor .master-o2SaxQ {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-v6kz6);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 59px;
}
.master-specialist-doctor .version-sKqQC0 {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.master-specialist-doctor .v1001-dhua6L {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.master-specialist-doctor .group-93713-sKqQC0 {
  background-color: transparent;
  height: calc(100% - 146px);
  left: 234px;
  position: absolute;
  top: 141px;
  width: calc(100% - 245px);
}
.master-specialist-doctor .rectangle-47148-YSZp5x {
  background-color: #ffffff;
  background-color: var(--white-ppqco);
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.master-specialist-doctor .path-1-YSZp5x {
  background-color: transparent;
  height: 1px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 48px;
  width: 100%;
}
.master-specialist-doctor .group-93702-YSZp5x {
  background-color: transparent;
  height: 19px;
  left: 59px;
  position: absolute;
  top: 15px;
  width: calc(100% - 111px);
}
.master-specialist-doctor .name-9kKlEj {
  background-color: transparent;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 39px;
}
.master-specialist-doctor .speciality-9kKlEj {
  background-color: transparent;
  height: calc(100% + 2px);
  left: calc(16.09% - 10px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 63px;
}
.master-specialist-doctor .location-9kKlEj {
  background-color: transparent;
  height: calc(100% + 2px);
  left: calc(32.14% - 18px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 56px;
}
.master-specialist-doctor .email-9kKlEj {
  background-color: transparent;
  height: calc(100% + 2px);
  left: calc(48.89% - 18px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 37px;
}
.master-specialist-doctor .address-9kKlEj {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(34.05% - 18px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 54px;
}
.master-specialist-doctor .mobile-no-9kKlEj {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(16.73% - 11px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 66px;
}
.master-specialist-doctor .actions-9kKlEj {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 50px;
}
.master-specialist-doctor .qdm-list-YSZp5x {
  background-color: transparent;
  left: 26px;
  position: absolute;
  top: 62px;
  width: 100%;
  height: 96vh;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.master-specialist-doctor .listitem-5hwxid {
  background-color: transparent;
  position: relative;
  width: calc(100% - 54px);
  /* height: auto; */
  
  min-height: 37px;

  margin: 0px 10px 10px 0px;
}
.master-specialist-doctor .group-93716-PPepdD {
  background-color: transparent;
  height: 12px;
  position: absolute;
  right: 32px;
  top: calc(27.89% - 3px);
  width: 37px;
}
.master-specialist-doctor .icon-material-delete-08sFtm {
  background-color: transparent;
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 9px;
}
.master-specialist-doctor .icon-material-edit-08sFtm {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 12px;
}
.master-specialist-doctor .administrator-PPepdD {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 4px;
  /* white-space: nowrap; */
  width: 69px;
}
.master-specialist-doctor .cardiology-PPepdD {
  background-color: transparent;
  height: auto;
  left: calc(18.5% - 0px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 4px;
  /* white-space: nowrap; */
  width: 54px;
}
.master-specialist-doctor .malaysia-PPepdD {
  background-color: transparent;
  height: auto;
  left: calc(33.44% - 20px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 4px;
  /* white-space: nowrap; */
  width: 90px;
}
.master-specialist-doctor .adminainqacom-PPepdD {
  background-color: transparent;
  height:auto;
  left: calc(49.56% - 60px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  /* white-space: nowrap; */
  width: 142px;
  line-break:anywhere;
}

.master-specialist-doctor .malaysia-MBegLh {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(34.81% - 61px);
  text-align: left;
  top: 4px;
  /* white-space: nowrap; */
  width: 100px;
}

.master-specialist-doctor .x1234567890-PPepdD {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(18.4% - 12px);
  text-align: left;
  top: 4px;
  white-space: nowrap;
  width: 64px;
}
.master-specialist-doctor .line-3-PPepdD {
  background-color: transparent;
  height: auto;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 32px;
  width: 100%;
}
.roboto-medium-woodsmoke-14px {
  color: #101010;
  color: var(--woodsmoke-b1vj9);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 14px;
  font-size: var(--font-size-m-f3fkn);
  font-style: normal;
  font-weight: 500;
}
.roboto-normal-dove-gray-11px {
  color: #6f6f6f;
  color: var(--dove-gray-pn67j);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 11px;
  font-size: var(--font-size-xs-wqjqx);
  font-style: normal;
  font-weight: 400;
}
.roboto-medium-dove-gray-14px {
  color: var(--dove-gray-pn67j-2);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4h6f3);
  font-size: 14px;
  font-size: var(--font-size-m-f3fkn);
  font-style: normal;
  font-weight: 500;
}
td{
  color: #6f6f6f;
  font-size: 12px;
  border-bottom: 1px solid #e0e0e0;
}
/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183753940) */

:root {
  --font-family-roboto-condensedbold-ejwhh: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-dmpsz: "Roboto", Helvetica;
  --font-size-xxxxl-oo0x9: 23px;
  --font-size-xxxl-25yo6: 19px;
  --font-size-xxl-tvqul: 18px;
  --font-size-xl-frkbb: 16px;
  --font-size-s-1mf3g: 10px;
  --font-size-m-5wcmv: 12px;
  --font-size-l-f36e7: 14px;
  --white-2yvc5: #ffffff;
  --sonic-silver-m940k: #757171;
  --pink-swan-t1b50: #b6b6b6;
  --dove-gray-w9w5m: #6d6e6f;
  --concord-jgr24: #7c7c7c;
  --blue-ribbon-qyyqe: #0063e7;
  --black-epruy: #000000;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
input:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
.roboto-normal-concord-12px {
  color: #7c7c7c;
  color: var(--concord-jgr24);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 12px;
  font-size: var(--font-size-m-5wcmv);
  font-style: normal;
  font-weight: 400;
}
.roboto-normal-black-12px {
  color: #000000;
  color: var(--black-epruy);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 12px;
  font-size: var(--font-size-m-5wcmv);
  font-style: normal;
  font-weight: 400;
}
.roboto-normal-sonic-silver-12px {
  color: #757171;
  color: var(--sonic-silver-m940k);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 12px;
  font-size: var(--font-size-m-5wcmv);
  font-style: normal;
  font-weight: 400;
}
.roboto-medium-dove-gray-14px {
  color: #6d6e6f;
  color: var(--dove-gray-w9w5m);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 14px;
  font-size: var(--font-size-l-f36e7);
  font-style: normal;
  font-weight: 500;
}
.visit-registration {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}
.visit-registration .qdm-list-JQaIUO {
  background-color: transparent;
  position: absolute;
  left: 240px;
  top: 163px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.visit-registration .listitem-Ci3S5d {
  background-color: transparent;
  position: relative;
  width: 75%;
  left: 3%;
  height: 100vh;
  margin: 0px 10px 10px 0px;
}
.visit-registration .listitem-Ci3S5s {
  background-color: transparent;
  position: relative;
  width: auto;
  display: grid;
  grid-template-columns: 45% 45%;
  justify-items: center;
  grid-gap: 12px;
  height: 396px;
  padding: 120px 0px;
  margin: 10px 0px 10px 0px !important;
  overflow-y: scroll;
}
.visit-registration .bg-jWfJzK {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.visit-registration .rectangle-32-Zxtv05 {
  background-color: #ffffff;
  background-color: var(--white-2yvc5);
  border: 0.3px solid #707070;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  height: 100%;
  left: calc(1%);
  position: absolute;
  top: 0px;
  width: 100%;
}
.visit-registration .rectangle-32-Zxtv06 {
  background-color: #ffffff;
  background-color: var(--white-2yvc5);
  border: 0.3px solid #707070;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #00000029;
  height: -webkit-max-content;
  height: max-content;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  overflow: scroll;
  padding: 24px;
}
.visit-registration .group-93710-Zxtv05 {
  background-color: transparent;
  height: 202px;
  left: 37px;
  position: absolute;
  top: calc(41.75% - 84px);
  width: 374px;
}
.visit-registration .group-93710-Zxtv06 {
  background-color: transparent;
  height: 70px;
  left: 37px;
  position: relative;
  top: calc(34.75% - 84px);
  width: 374px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 10px #efefef;
  border-radius: 8px;
  cursor: pointer;
}
.visit-registration .group-93710-Zxtv06:hover {
  border: solid 1px rgb(0 99 231);
}
.visit-registration .patient-name-vGUBia {
  background-color: transparent;
  height: 18px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  text-align: left;
  top: 3px;
  white-space: nowrap;
  width: 73px;
}
.visit-registration .administrator-vGUBia {
  background-color: transparent;
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 3px;
  white-space: nowrap;
  width: 75px;
}
.visit-registration .x1234567890-vGUBia {
  background-color: transparent;
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: 6px;
  text-align: left;
  top: calc(20.65% - 4px);
  white-space: nowrap;
  width: 69px;
}
.visit-registration .dfhs5-d7-vGUBia {
  background-color: transparent;
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: 21px;
  text-align: left;
  top: calc(40.76% - 7px);
  white-space: nowrap;
  width: 54px;
}
.visit-registration .x654343-vGUBia {
  background-color: transparent;
  bottom: calc(38.59% - 7px);
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: 33px;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.visit-registration .malaysia-vGUBia {
  background-color: transparent;
  bottom: calc(18.48% - 3px);
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: 16px;
  text-align: left;
  white-space: nowrap;
  width: 59px;
}
.visit-registration .x54637289-vGUBia {
  background-color: transparent;
  bottom: -4px;
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: 19px;
  text-align: left;
  white-space: nowrap;
  width: 56px;
}
.visit-registration .mobile-no-vGUBia {
  background-color: transparent;
  height: 18px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  text-align: left;
  top: calc(20.65% - 4px);
  white-space: nowrap;
  width: 57px;
}
.visit-registration .id-card-vGUBia {
  background-color: transparent;
  height: 18px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  text-align: left;
  top: calc(40.76% - 7px);
  white-space: nowrap;
  width: 41px;
}
.visit-registration .emp-no-vGUBia {
  background-color: transparent;
  bottom: calc(38.59% - 7px);
  height: 18px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 45px;
}
.visit-registration .facility-vGUBia {
  background-color: transparent;
  bottom: calc(18.48% - 3px);
  height: 18px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 40px;
}
.visit-registration .mrn-vGUBia {
  background-color: transparent;
  bottom: -4px;
  height: 18px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 31px;
}
.visit-registration .x-vGUBia {
  background-color: transparent;
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: calc(44.2% - 2px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 5px;
}
.visit-registration .x-YItia6 {
  background-color: transparent;
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: calc(44.2% - 2px);
  text-align: left;
  top: calc(20.11% - 4px);
  white-space: nowrap;
  width: 5px;
}
.visit-registration .x-BQivjw {
  background-color: transparent;
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: calc(44.2% - 2px);
  text-align: left;
  top: calc(40.22% - 7px);
  white-space: nowrap;
  width: 5px;
}
.visit-registration .x-I3fRhZ {
  background-color: transparent;
  bottom: calc(39.13% - 7px);
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: calc(44.2% - 2px);
  text-align: left;
  white-space: nowrap;
  width: 5px;
}
.visit-registration .x-DdQTBb {
  background-color: transparent;
  bottom: calc(19.02% - 3px);
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: calc(44.2% - 2px);
  text-align: left;
  white-space: nowrap;
  width: 5px;
}
.visit-registration .x-UxjeUw {
  background-color: transparent;
  bottom: -3px;
  height: 18px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  right: calc(44.2% - 2px);
  text-align: left;
  white-space: nowrap;
  width: 5px;
}
.visit-registration .line-5-Zxtv05 {
  background-color: transparent;
  height: 1px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 100%;
}
.visit-registration .search-result-Zxtv05 {
  background-color: transparent;
  color: #000000;
  color: var(--black-epruy);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 16px;
  font-size: var(--font-size-xl-frkbb);
  font-style: normal;
  font-weight: 500;
  height: 23px;
  left: 15px;
  letter-spacing: 0px;
  line-height: 16px;
  position: absolute;
  text-align: left;
  top: 10px;
  white-space: nowrap;
  width: 105px;
}
.visit-registration .component-93700-jWfJzK {
  background-color: transparent;
  height: 76px;
  position: absolute;
  right: 26px;
  top: calc(24.57% - 19px);
  width: 313px;
}
.visit-registration .qdm-dropdown-cshRPh {
  background-color: transparent;
  bottom: 0px;
  height: 35px;
  left: 1px;
  position: absolute;
  width: 100%;
}
.visit-registration .rectangle-47161-joAA5k {
  background-color: #ffffff;
  background-color: var(--white-2yvc5);
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.visit-registration .select-speciality-joAA5k {
  background-color: transparent;
  bottom: calc(111.54% - 23px);
  color: #b6b6b6;
  color: var(--pink-swan-t1b50);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 14px;
  font-size: var(--font-size-l-f36e7);
  font-style: normal;
  font-weight: 400;
  height: 21px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 104px;
}
.visit-registration .group-92805-joAA5k {
  background-color: transparent;
  height: 24px;
  position: absolute;
  right: 7px;
  top: 2px;
  width: 24px;
}
.visit-registration .icons8-expand-arrow-xCtxcO {
  background-color: transparent;
  bottom: 3px;
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.visit-registration .speciality-cshRPh {
  background-color: transparent;
  color: #000000;
  color: var(--black-epruy);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 14px;
  font-size: var(--font-size-l-f36e7);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 63px;
}
.visit-registration .qdm-button-jWfJzK {
  background-color: transparent;
  bottom: 30px;
  height: 26px;
  position: absolute;
  right: calc(50% - 110px);
  width: 221px;
}
.visit-registration .rectangle-45330-a3YOxN {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-qyyqe);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.visit-registration .back-a3YOxN {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-qyyqe);
  color: #ffffff;
  color: var(--white-2yvc5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 14px;
  font-size: var(--font-size-l-f36e7);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 6px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
.visit-registration .visit-a3YOxN {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-qyyqe);
  color: #ffffff;
  color: var(--white-2yvc5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 14px;
  font-size: var(--font-size-l-f36e7);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 6px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
.visit-registration .close-icon-jWfJzK {
  background-color: transparent;
  height: 9px;
  object-fit: cover;
  position: absolute;
  right: 17px;
  top: 16px;
  width: 9px;
}
.visit-registration .path-93229-jWfJzK {
  background-color: transparent;
  height: 202px;
  object-fit: cover;
  position: absolute;
  right: 45.59%;
  top: calc(41.49% - 84px);
  width: 1px;
}
.visit-registration .group-93721-JQaIUO {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
  border-bottom: 1px solid lightgrey;
}
.visit-registration .rectangle-47180-sXrDpa {
  background-color: #ffffff;
  background-color: var(--white-2yvc5);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.visit-registration .login-avatar-sXrDpa {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: calc(50% - 18px);
  width: 134px;
}
.visit-registration .rectangle-47177-8Kn8uO {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-qyyqe);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.visit-registration .rectangle-47178-8Kn8uO {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.visit-registration .rakin-8Kn8uO {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-qyyqe);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 14px;
  font-size: var(--font-size-l-f36e7);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.visit-registration .icon-awesome-chevron-down-8Kn8uO {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.visit-registration .visit-registration-sXrDpa {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-epruy);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 18px;
  font-size: var(--font-size-xxl-tvqul);
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 140px;
}
.visit-registration .group-93720-JQaIUO {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
  border-right: 1px solid lightgray;
}
.visit-registration .rectangle-47173-Io4y1R {
  background-color: #ffffff;
  background-color: var(--white-2yvc5);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.visit-registration .group-93719-Io4y1R {
  background-color: transparent;
  height: 420px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 197px;
}
.visit-registration .logo-01bKn4 {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 17px);
}
.visit-registration .x51-client_profile_subscription-10-inMWm9 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.visit-registration .global-doctors-inMWm9 {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-qyyqe);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 23px;
  font-size: var(--font-size-xxxxl-oo0x9);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.visit-registration .span0-PYDfvh {
  font-family: var(--font-family-roboto-dmpsz-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.visit-registration .span1-PYDfvh {
  font-size: 19px;
  font-size: var(--font-size-xxxl-25yo6);
  font-style: normal;
}
.visit-registration .version-01bKn4 {
  background-color: transparent;
  height: 13px;
  position: absolute;
  right: 0px;
  top: 61px;
  width: 33px;
}
.visit-registration .v1001-mjI7WH {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 10px;
  font-size: var(--font-size-s-1mf3g);
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.visit-registration .login-details-01bKn4 {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.87% - 26px);
  width: 148px;
}
.visit-registration .hello-CP9rEC {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 15.2px;
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.visit-registration .mr-rakin-CP9rEC {
  background-color: transparent;
  color: #000000;
  color: var(--black-epruy);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.visit-registration .sathishrakinainqacom-CP9rEC {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-epruy);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.visit-registration .line-9-CP9rEC {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.visit-registration .reports-01bKn4 {
  background-color: transparent;
  bottom: 40px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.visit-registration .reports-5zPeTW {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.visit-registration .download-7-5zPeTW {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.visit-registration .patient-regn-01bKn4 {
  background-color: transparent;
  bottom: calc(44.75% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 40px);
}
.visit-registration .patient-registration-2aw2Za {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.visit-registration .rectangle-47175-2aw2Za {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.visit-registration .master-01bKn4 {
  background-color: transparent;
  bottom: 104px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.visit-registration .master-HNJFiO {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.visit-registration .material-master-data-HNJFiO {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.visit-registration .visit-regn-01bKn4 {
  background-color: transparent;
  top: 220px;
  height: 38px;
  left: 7px;
  position: absolute;
  width: calc(100% - 12px);
}
.visit-registration .rectangle-47179-khzOsH {
  background-color: #ece3d3;
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.visit-registration .visit-registration-khzOsH {
  background-color: transparent;
  bottom: calc(38.24% - 8px);
  color: #000000;
  color: var(--black-epruy);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 14px;
  font-size: var(--font-size-l-f36e7);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(42.11% - 46px);
  text-align: left;
  white-space: nowrap;
  width: 109px;
}
.visit-registration .download-6-khzOsH {
  background-color: transparent;
  height: 17px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: calc(47.62% - 8px);
  width: 16px;
}
.visit-registration .group-93707-JQaIUO {
  background-color: transparent;
  height: 48px;
  position: absolute;
  right: calc(32.35% - 270px);
  top: 95px;
  width: 833px;
}
.visit-registration .qdm-button-E62kL9 {
  background-color: transparent;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 1px;
  width: 88px;
}
.visit-registration .path-93230-6vogkz {
  background-color: transparent;
  height: calc(100% + 18px);
  left: -9px;
  object-fit: cover;
  position: absolute;
  top: -6px;
  width: calc(100% + 18px);
}
.visit-registration .search-6vogkz {
  background-color: transparent;
  color: #ffffff;
  color: var(--white-2yvc5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 16px;
  font-size: var(--font-size-xl-frkbb);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 16px;
  white-space: nowrap;
  height: calc(100% + 18px);
  width: calc(100% + 18px);
  display: grid;
  place-items: center;
}
.visit-registration .qdm-textinput-E62kL9 {
  background-color: transparent;
  height: 100%;
  left: 113px;
  position: absolute;
  display: grid;
  grid-template-columns: 100%;
  top: 0px;
  width: calc(100% - 200px);
}
.visit-registration .rectangle-42910-lTxfw5 {
  background-color: #ffffff;
  background-color: var(--white-2yvc5);
  box-shadow: 0px 2px 6px #00000029;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.visit-registration .search-lTxfw5 {
  background-color: transparent;
  border: 0;
  bottom: calc(46.64% - 8px);
  color: #b6b6b6;
  color: var(--pink-swan-t1b50);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 12px;
  font-size: var(--font-size-m-5wcmv);
  font-style: normal;
  font-weight: 400;
  height: 18px;
  left: 18px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 58px;
}
.visit-registration .search-lTxfw5::-webkit-input-placeholder {
  color: #b6b6b699;
}
.visit-registration .search-lTxfw5::placeholder {
  color: #b6b6b699;
}
.visit-registration .qdm-dropdown-E62kL9 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 113px;
}
.visit-registration .path-93231-QJR5zB {
  background-color: transparent;
  height: calc(100% + 18px);
  left: -12px;
  object-fit: cover;
  position: absolute;
  top: -6px;
  width: calc(100% + 18px);
}
.visit-registration .search-by-QJR5zB {
  background-color: transparent;
  color: #b6b6b6;
  color: var(--pink-swan-t1b50);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-dmpsz);
  font-size: 12px;
  font-size: var(--font-size-m-5wcmv);
  font-style: normal;
  font-weight: 400;
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  top: calc(18.11% - 3px);
  white-space: nowrap;
  width: 55px;
}
.visit-registration .icons8-expand-arrow-QJR5zB {
  background-color: transparent;
  bottom: calc(45.45% - 2px);
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 12px;
  width: 9px;
}

:root {
  --font-family-roboto-condensedbold-lnhen: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-qvfbi: "Roboto", Helvetica;
  --font-size-xxxxl-clgiw: 23px;
  --font-size-xxxl-arcsm: 19px;
  --font-size-xxl-pnu6u: 16px;
  --font-size-xl-8sdb3: 15.2px;
  --font-size-s-d9f10: 10px;
  --font-size-m-0a622: 12px;
  --font-size-l-7y5i4: 14px;
  --white-oex6u: #ffffff;
  --pink-swan-f6m8k: #b6b6b6;
  --bon-jour-6nu54: #e0e0e0;
  --blue-ribbon-7agq5: #0063e7;
  --black-f5rlx: #000000;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
input:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}

.patient-registration-updated .path-93228-9HxdoZ {
  background-color: transparent;
  height: 0px;
  object-fit: cover;
  position: absolute;
  right: 22.24%;
  top: 155px;
  width: 0px;
}
.patient-registration-updated .group-93721-9HxdoZ {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.patient-registration-updated .rectangle-47180-0xx0Gd {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .login-avatar-0xx0Gd {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 19px;
  top: calc(50% - 18px);
  width: 134px;
}
.patient-registration-updated .rectangle-47177-Xm3thl {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-7agq5);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .rectangle-47178-Xm3thl {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.patient-registration-updated .rakin-Xm3thl {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-7agq5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 14px;
  font-size: var(--font-size-l-7y5i4);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.patient-registration-updated .icon-awesome-chevron-down-Xm3thl {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.patient-registration-updated .form-9HxdoZ {
}
.patient-registration-updated .rectangle-47148-Y8vGsH {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border: 0.5px solid #e0e0e0;
  border: 0.5px solid var(--bon-jour-6nu54);
  border-radius: 8px;
  height: 100%;
  left: 2px;
  position: absolute;
  top: 0px;
  width: calc(100% - 2px);
}
.patient-registration-updated .group-93725-Y8vGsH {
  background-color: transparent;
  height: calc(100% - 113px);
  left: 34px;
  position: absolute;
  top: 86px;
  width: 50%;
}
.patient-registration-updated .component-28-L99nfx {
  background-color: transparent;
  height: 30px;
  left: 1px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .qdm-textinput-CLsSDU {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .textinput-fxl23l {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .full-name-fxl23l {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 58px;
}
.patient-registration-updated .full-name-fxl23l::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .full-name-fxl23l::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .component-69-L99nfx {
  background-color: transparent;
  bottom: 1px;
  height: 92px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.patient-registration-updated .qdm-textinput-6xHVnp {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .textinput-OoJkvA {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .type-here-OoJkvA {
  background-color: transparent;
  border: 0;
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 9px;
  white-space: nowrap;
  width: 65px;
}
.patient-registration-updated .type-here-OoJkvA::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .type-here-OoJkvA::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .component-68-L99nfx {
  background-color: transparent;
  bottom: calc(25.45% - 0px);
  height: 30px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.patient-registration-updated .qdm-dropdown-Y59cwJ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .textinput-PyNEHr {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .facility-name-PyNEHr {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 11px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 75px;
}
.patient-registration-updated .facility-name-PyNEHr::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .facility-name-PyNEHr::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .group-93716-PyNEHr {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 11px;
  top: 4px;
  width: 24px;
}
.patient-registration-updated .icons8-expand-arrow-phubBH {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.patient-registration-updated .group-93727-L99nfx {
  background-color: transparent;
  height: 30px;
  left: 1px;
  position: absolute;
  top: calc(15.02% - 9px);
  width: 100%;
}
.patient-registration-updated .component-78-7XIOuo {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 166px;
}
.patient-registration-updated .qdm-dropdown-w3DTLW {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .textinput-h9IFxq {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .gender-h9IFxq {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 11px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 36px;
}
.patient-registration-updated .gender-h9IFxq::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .gender-h9IFxq::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .group-93716-h9IFxq {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 11px;
  top: 4px;
  width: 24px;
}
.patient-registration-updated .icons8-expand-arrow-pp2j0Y {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.patient-registration-updated .component-79-7XIOuo {
  background-color: transparent;
  height: 100%;
  position: absolute;
  right: calc(38.5% - 65px);
  top: 0px;
  width: 168px;
}
.patient-registration-updated .qdm-textinput-ba6bAn {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .textinput-acaIES {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .dd-mm-yyyy-acaIES {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 11px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 77px;
}
.patient-registration-updated .dd-mm-yyyy-acaIES::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .dd-mm-yyyy-acaIES::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .component-80-7XIOuo {
  background-color: transparent;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 95px;
}
.patient-registration-updated .qdm-textinput-b5GQFF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .textinput-H6NVcJ {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .age-H6NVcJ {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 11px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 22px;
}
.patient-registration-updated .age-H6NVcJ::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .age-H6NVcJ::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .component-67-L99nfx {
  background-color: transparent;
  height: 30px;
  left: 0px;
  position: absolute;
  top: calc(29.82% - 9px);
  width: 100%;
}
.patient-registration-updated .qdm-textinput-IE6RqY {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .textinput-OeN88b {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .enter-your-emp-no-OeN88b {
  background-color: transparent;
  border: 0;
  height: 18px;
  left: 11px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: calc(50% - 9px);
  white-space: nowrap;
  width: 106px;
}
.patient-registration-updated .enter-your-emp-no-OeN88b::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .enter-your-emp-no-OeN88b::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .component-93700-L99nfx {
  background-color: transparent;
  height: 30px;
  left: 1px;
  position: absolute;
  top: calc(44.73% - 13px);
  width: 100%;
}
.patient-registration-updated .qdm-textinput-8Wz9jn {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .rectangle-42910-BQUI8q {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .enter-your-contact-number-BQUI8q {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: calc(38.35% - 57px);
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 149px;
}
.patient-registration-updated .enter-your-contact-number-BQUI8q::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .enter-your-contact-number-BQUI8q::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .group1234567-BQUI8q {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100px;
}
.patient-registration-updated .rectangle-47161-xRkqzn {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .x91-ind-xRkqzn {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 45px;
}
.patient-registration-updated .group-92805-xRkqzn {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 3px;
  top: 3px;
  width: 24px;
}
.patient-registration-updated .icons8-expand-arrow-AnD900 {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.patient-registration-updated .component-93700-NWHnxr {
  background-color: transparent;
  bottom: calc(40.36% - 12px);
  height: 30px;
  left: 1px;
  position: absolute;
  width: 100%;
}
.patient-registration-updated .qdm-textinput-UtUZ2W {
  background-color: transparent;
  height: 100%;
  left: 99px;
  position: absolute;
  top: 0px;
  width: calc(100% - 99px);
}
.patient-registration-updated .rectangle-42910-vpLOs5 {
  background-color: transparent;
  height: 100%;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .enter-your-id-number-vpLOs5 {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 18px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 119px;
}
.patient-registration-updated .enter-your-id-number-vpLOs5::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .enter-your-id-number-vpLOs5::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .qdm-dropdown-UtUZ2W {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100px;
}
.patient-registration-updated .rectangle-47161-mxhRxl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .passport-mxhRxl {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 50px;
}
.patient-registration-updated .group-92805-mxhRxl {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 3px;
  top: 3px;
  width: 24px;
}
.patient-registration-updated .icons8-expand-arrow-wSsRwg {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.patient-registration-updated .component-76-Y8vGsH {
  background-color: transparent;
  height: 30px;
  position: absolute;
  right: 40px;
  top: 87px;
  width: 40%;
}
.patient-registration-updated .qdm-dropdown-bFecFd {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .textinput-I3xaNo {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .select-specialist-I3xaNo {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 11px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 92px;
}
.patient-registration-updated .select-specialist-I3xaNo::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .select-specialist-I3xaNo::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .group-93716-I3xaNo {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 11px;
  top: 4px;
  width: 24px;
}
.patient-registration-updated .icons8-expand-arrow-fkJOGC {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.patient-registration-updated .component-77-Y8vGsH {
  background-color: transparent;
  height: 30px;
  position: absolute;
  right: 40px;
  top: 160px;
  width: 40%;
}
.patient-registration-updated .qdm-dropdown-pqYpRd {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .textinput-hEBCAk {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .purpose-of-visit-hEBCAk {
  background-color: transparent;
  border: 0;
  height: 16px;
  left: 11px;
  padding: 0;
  position: absolute;
  resize: none;
  top: calc(42.86% - 7px);
  width: 86px;
}
.patient-registration-updated .group-93716-hEBCAk {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 11px;
  top: 4px;
  width: 24px;
}
.patient-registration-updated .icons8-expand-arrow-KiAqpC {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.patient-registration-updated .group-93724-Y8vGsH {
  background-color: transparent;
  bottom: 27px;
  height: 26px;
  position: absolute;
  right: 40px;
  width: 278px;
}
.patient-registration-updated .qdm-button-k6tZF1 {
  background-color: #0063e7;
  height: 100%;
  padding: 8px 10px;
  display: grid;
  border-radius: 5px;
  place-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 114px;
}
.patient-registration-updated .rectangle-45330-Eqv012 {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-7agq5);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .register-visit-Eqv012 {
  background-color: transparent;
  color: #ffffff;
  color: var(--white-oex6u);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 14px;
  font-size: var(--font-size-l-7y5i4);
  font-style: normal;
  font-weight: 500;
  left: 15px;
  letter-spacing: 0px;
  line-height: 12px;
  top: 6px;
  white-space: nowrap;
  display: grid;
  place-items: center;
}
.patient-registration-updated .cancel-button-k6tZF1 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 114px;
}
.patient-registration-updated .rectangle-47166-HEJyH2 {
  background-color: transparent;
  border: 1px solid #0063e7;
  border: 1px solid var(--blue-ribbon-7agq5);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .cancel-HEJyH2 {
  background-color: transparent;
  color: #0063e7;
  color: var(--blue-ribbon-7agq5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 16px;
  font-size: var(--font-size-xxl-pnu6u);
  font-style: normal;
  font-weight: 400;
  height: calc(100% - 3px);
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  right: calc(48.41% - 25px);
  text-align: left;
  top: -6px;
  white-space: nowrap;
  width: 51px;
}
.patient-registration-updated .path-93232-Y8vGsH {
  background-color: transparent;
  height: 1px;
  object-fit: cover;
  position: absolute;
  right: 3px;
  top: 46px;
  width: 533px;
}
.patient-registration-updated .visiting-details-Y8vGsH {
  background-color: transparent;
  color: #000000;
  color: var(--black-f5rlx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 16px;
  font-size: var(--font-size-xxl-pnu6u);
  font-style: normal;
  font-weight: 500;
  height: 23px;
  letter-spacing: 0px;
  line-height: 16px;
  position: absolute;
  right: 33%;
  text-align: left;
  top: 16px;
  white-space: nowrap;
  width: 110px;
}
.patient-registration-updated .path-93228-Y8vGsH {
  background-color: transparent;
  height: 1px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 46px;
  width: 537px;
}
.patient-registration-updated .patient-details-Y8vGsH {
  background-color: transparent;
  color: #000000;
  color: var(--black-f5rlx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 16px;
  font-size: var(--font-size-xxl-pnu6u);
  font-style: normal;
  font-weight: 500;
  height: 23px;
  left: 34px;
  letter-spacing: 0px;
  line-height: 16px;
  position: absolute;
  text-align: left;
  top: 16px;
  white-space: nowrap;
  width: 106px;
}
.patient-registration-updated .group-93707-9HxdoZ {
  background-color: transparent;
  height: 48px;
  position: absolute;
  right: 42px;
  top: 97px;
  width: 1070px;
}
.patient-registration-updated .qdm-button-ZI6lSc {
  background-color: transparent;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 1px;
  width: 88px;
}
.patient-registration-updated .path-93230-QpZMoe {
  background-color: transparent;
  height: calc(100% + 18px);
  left: -9px;
  object-fit: cover;
  position: absolute;
  top: -6px;
  width: calc(100% + 18px);
}
.patient-registration-updated .search-QpZMoe {
  background-color: transparent;
  color: #ffffff;
  color: var(--white-oex6u);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 16px;
  font-size: var(--font-size-xxl-pnu6u);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 16px;
  white-space: nowrap;
  display: grid;
  place-items: center;
}
.patient-registration-updated .qdm-textinput-ZI6lSc {
  background-color: transparent;
  height: 100%;
  left: 113px;
  position: absolute;
  top: 0px;
  width: calc(100% - 200px);
}
.patient-registration-updated .rectangle-42910-nOoc3L {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  box-shadow: 0px 2px 6px #00000029;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .search-nOoc3L {
  background-color: transparent;
  border: 0;
  bottom: calc(46.64% - 8px);
  height: 18px;
  left: 18px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 58px;
}
.patient-registration-updated .search-nOoc3L::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .search-nOoc3L::placeholder {
  color: #b6b6b699;
}
.patient-registration-updated .qdm-dropdown-ZI6lSc {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 113px;
}
.patient-registration-updated .path-93231-Mct1oU {
  background-color: transparent;
  height: calc(100% + 18px);
  left: -12px;
  object-fit: cover;
  position: absolute;
  top: -6px;
  width: calc(100% + 18px);
}
.patient-registration-updated .search-by-Mct1oU {
  background-color: transparent;
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  top: calc(18.11% - 3px);
  white-space: nowrap;
  width: 55px;
}
.patient-registration-updated .icons8-expand-arrow-Mct1oU {
  background-color: transparent;
  bottom: calc(45.45% - 2px);
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 12px;
  width: 9px;
}
.patient-registration-updated .group-93720-9HxdoZ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
}
.patient-registration-updated .rectangle-47173-LKOm2g {
  background-color: #ffffff;
  background-color: var(--white-oex6u);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .group-93719-LKOm2g {
  background-color: transparent;
  height: 358px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 197px;
}
.patient-registration-updated .logo-0usyo1 {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 17px);
}
.patient-registration-updated .x51-client_profile_subscription-10-9D2E7H {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.patient-registration-updated .global-doctors-9D2E7H {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-7agq5);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 23px;
  font-size: var(--font-size-xxxxl-clgiw);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.patient-registration-updated .span0-wRMixY {
  font-family: var(--font-family-roboto-qvfbi-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.patient-registration-updated .span1-wRMixY {
  font-size: 19px;
  font-size: var(--font-size-xxxl-arcsm);
  font-style: normal;
}
.patient-registration-updated .version-0usyo1 {
  background-color: transparent;
  height: 13px;
  position: absolute;
  right: 0px;
  top: calc(17.68% - 2px);
  width: 33px;
}
.patient-registration-updated .v1001-Ovr66t {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 10px;
  font-size: var(--font-size-s-d9f10);
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.patient-registration-updated .login-details-0usyo1 {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(34.4% - 32px);
  width: 148px;
}
.patient-registration-updated .hello-Zk8ZLs {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 15.2px;
  font-size: var(--font-size-xl-8sdb3);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.patient-registration-updated .mr-rakin-Zk8ZLs {
  background-color: transparent;
  color: #000000;
  color: var(--black-f5rlx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.patient-registration-updated .sathishrakinainqacom-Zk8ZLs {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-f5rlx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.patient-registration-updated .line-9-Zk8ZLs {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.patient-registration-updated .navitems-0usyo1 {
  background-color: transparent;
  bottom: 0px;
  height: 140px;
  left: 7px;
  position: absolute;
  width: calc(100% - 12px);
}
.patient-registration-updated .reports-kTvPd2 {
  background-color: transparent;
  bottom: 0px;
  height: 19px;
  left: 9px;
  position: absolute;
  width: 80px;
}
.patient-registration-updated .reports-uZa8eO {
  background-color: transparent;
  color: #6d6e6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 14px;
  font-size: var(--font-size-l-7y5i4);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.patient-registration-updated .download-7-uZa8eO {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.patient-registration-updated .master-kTvPd2 {
  background-color: transparent;
  bottom: calc(45.83% - 9px);
  height: 20px;
  left: 8px;
  position: absolute;
  width: 76px;
}
.patient-registration-updated .master-WWbIxT {
  background-color: transparent;
  color: #6d6e6f;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 14px;
  font-size: var(--font-size-l-7y5i4);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.patient-registration-updated .material-master-data-WWbIxT {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 18px;
}
.patient-registration-updated .visit-regn-kTvPd2 {
  background-color: transparent;
  height: 38px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .rectangle-47179-yV65GN {
  background-color: #fff9ed;
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration-updated .visit-registration-yV65GN {
  background-color: transparent;
  bottom: calc(32.35% - 7px);
  color: #000000;
  color: var(--black-f5rlx);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 14px;
  font-size: var(--font-size-l-7y5i4);
  font-style: normal;
  font-weight: 500;
  height: 21px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(46.05% - 50px);
  text-align: left;
  white-space: nowrap;
  width: 109px;
}
.patient-registration-updated .download-6-yV65GN {
  background-color: transparent;
  bottom: calc(47.62% - 8px);
  height: 17px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  width: 16px;
}
.roboto-light-pink-swan-12px {
  color: #b6b6b6;
  color: var(--pink-swan-f6m8k);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 12px;
  font-size: var(--font-size-m-0a622);
  font-style: normal;
  font-weight: 300;
}
.roboto-normal-pink-swan-12px {
  color: #b6b6b6;
  color: var(--pink-swan-f6m8k);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-qvfbi);
  font-size: 12px;
  font-size: var(--font-size-m-0a622);
  font-style: normal;
  font-weight: 400;
}
.border-1px-bon-jour {
  border: 1px solid #e0e0e0;
  border: 1px solid var(--bon-jour-6nu54);
}


textarea{
  resize: none;
}
/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183753400) */

:root {
  --font-family-roboto-condensedbold-eb3jv: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-4kl4n: "Roboto", Helvetica;
  --font-size-xxxxl-wlhux: 23px;
  --font-size-xxxl-6h4pm: 19px;
  --font-size-xxl-fqp1a: 18px;
  --font-size-xl-c0xm8: 16px;
  --font-size-s-nla82: 10px;
  --font-size-m-aw07k: 12px;
  --font-size-l-0h1ju: 14px;
  --white-buigd: #ffffff;
  --pink-swan-xim82: #b6b6b6;
  --dove-gray-e5xhq: #6d6e6f;
  --bon-jour-pv96a: #e0e0e0;
  --blue-ribbon-2kgi7: #0063e7;
  --black-a6xpv: #000000;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
input:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
.patient-registration {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.patient-registration .group-93722-zVTIDx {
  background-color: transparent;
  height: 617px;
  position: absolute;
  right: calc(36.65% - 196px);
  top: 107px;
  width: 536px;
}
.patient-registration .rectangle-47148-txmB1b {
  background-color: #ffffff;
  background-color: var(--white-buigd);
  border: 0.5px solid #e0e0e0;
  border: 0.5px solid var(--bon-jour-pv96a);
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  height: 617px;
  left: calc(50% - 268px);
  position: absolute;
  top: 0px;
  width: 536px;
}
.patient-registration .qdm-textinput-txmB1b {
  background-color: transparent;
  height: 30px;
  left: 35px;
  position: absolute;
  top: 85px;
  width: calc(100% - 81px);
}
.patient-registration .textinput-O1YFj2 {
  background-color: #ffffff;
  background-color: var(--white-buigd);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .first-name-O1YFj2 {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 61px;
}
.patient-registration .first-name-O1YFj2::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration .first-name-O1YFj2::placeholder {
  color: #b6b6b699;
}
.patient-registration .qdm-textinput-mReW0w {
  background-color: transparent;
  bottom: calc(12.12% - 26px);
  height: 92px;
  left: 35px;
  position: absolute;
  width: calc(100% - 81px);
}
.patient-registration .textinput-B9KPiV {
  background-color: #ffffff;
  background-color: var(--white-buigd);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .type-here-B9KPiV {
  background-color: transparent;
  border: 0;
  height: 18px;
  left: 12px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 9px;
  white-space: nowrap;
  width: 65px;
}
.patient-registration .type-here-B9KPiV::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration .type-here-B9KPiV::placeholder {
  color: #b6b6b699;
}
.patient-registration .qdm-dropdown-txmB1b {
  background-color: transparent;
  bottom: calc(30.86% - 13px);
  height: 30px;
  left: 35px;
  position: absolute;
  width: calc(100% - 81px);
}
.patient-registration .textinput-Dtetxq {
  background-color: #ffffff;
  background-color: var(--white-buigd);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .facility-name-Dtetxq {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 11px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 75px;
}
.patient-registration .facility-name-Dtetxq::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration .facility-name-Dtetxq::placeholder {
  color: #b6b6b699;
}
.patient-registration .group-93716-Dtetxq {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 11px;
  top: 4px;
  width: 24px;
}
.patient-registration .icons8-expand-arrow-ryK7m2 {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.patient-registration .qdm-textinput-YOgISv {
  background-color: transparent;
  height: 30px;
  left: 35px;
  position: absolute;
  top: calc(46.60% - 11px);
  width: calc(100% - 81px);
}
.patient-registration .textinput-JWIlTZ {
  background-color: #ffffff;
  background-color: var(--white-buigd);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .enter-your-emp-no-JWIlTZ {
  background-color: transparent;
  border: 0;
  height: 18px;
  left: 11px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: calc(50% - 9px);
  white-space: nowrap;
  width: 106px;
}
.patient-registration .enter-your-emp-no-JWIlTZ::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration .enter-your-emp-no-JWIlTZ::placeholder {
  color: #b6b6b699;
}
.patient-registration .qdm-textinput-ILayjh {
  background-color: transparent;
  height: 30px;
  left: 35px;
  position: absolute;
  top: calc(57.90% - 14px);
  width: calc(100% - 81px);
}
.patient-registration .rectangle-42910-wC95Oj {
  background-color: #ffffff;
  background-color: var(--white-buigd);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .enter-your-contact-number-wC95Oj {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: calc(38.35% - 57px);
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 149px;
}
.patient-registration .enter-your-contact-number-wC95Oj::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration .enter-your-contact-number-wC95Oj::placeholder {
  color: #b6b6b699;
}
.patient-registration .group1234567-wC95Oj {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 88px;
}
.patient-registration .rectangle-47161-HQOux3 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .x91-HQOux3 {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  color: #b6b6b6;
  color: var(--pink-swan-xim82);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 12px;
  font-size: var(--font-size-m-aw07k);
  font-style: normal;
  font-weight: 400;
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 15px;
}
.patient-registration .group-92805-HQOux3 {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 8px;
  top: 3px;
  width: 24px;
}
.patient-registration .icons8-expand-arrow-TwALxf {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.patient-registration .component-93700-txmB1b {
  background-color: transparent;
  height: 30px;
  left: 35px;
  position: absolute;
  top: calc(25.36% - 8px);
  width: calc(100% - 81px);
}
.patient-registration .component-93700-txmKli {
  background-color: transparent;
  height: 30px;
  left: 35px;
  position: absolute;
  top: calc(36.28% - 11px);
  width: calc(100% - 81px);
}
.patient-registration .qdm-textinput-5VfWu1 {
  background-color: transparent;
  height: 100%;
  left: 99px;
  position: absolute;
  top: 0px;
  width: calc(100% - 99px);
}

.patient-registration .qdm-textinput-5VfWu2 {
  background-color: transparent;
  height: 100%;
  left: 326px;
  position: absolute;
  top: 0px;
}
.patient-registration .rectangle-42910-bIxUxN {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .enter-your-id-number-bIxUxN {
  background-color: transparent;
  border: 0;
  bottom: calc(50% - 9px);
  height: 18px;
  left: 18px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 119px;
}
.patient-registration .enter-your-id-number-bIxUxN::-webkit-input-placeholder {
  color: #b6b6b699;
}
.patient-registration .enter-your-id-number-bIxUxN::placeholder {
  color: #b6b6b699;
}
.patient-registration .qdm-dropdown-5VfWu1 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100px;
}

.patient-registration .qdm-dropdown-5VfWu3 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100px;
}

.patient-registration .rectangle-47161-nKos9x {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .passport-nKos9x {
  background-color: transparent;
  bottom: calc(129.17% - 23px);
  color: #b6b6b6;
  color: var(--pink-swan-xim82);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 12px;
  font-size: var(--font-size-m-aw07k);
  font-style: normal;
  font-weight: 400;
  height: 18px;
  left: 12px;
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 50px;
}
.patient-registration .group-92805-nKos9x {
  background-color: transparent;
  height: calc(100% - 6px);
  position: absolute;
  right: 3px;
  top: 3px;
  width: 24px;
}
.patient-registration .icons8-expand-arrow-J5KQkV {
  background-color: transparent;
  bottom: calc(48.45% - 3px);
  height: 7px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  width: calc(100% - 11px);
}
.patient-registration .qdm-button-txmB1b {
  background-color: transparent;
  bottom: 34px;
  height: 26px;
  position: absolute;
  right: 46px;
  width: 84px;
  cursor: pointer;
}
.patient-registration .rectangle-45330-J1HMpe {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-2kgi7);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .submit-J1HMpe {
  background-color: #0063e7;
  background-color: var(--blue-ribbon-2kgi7);
  color: #ffffff;
  color: var(--white-buigd);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 14px;
  font-size: var(--font-size-l-0h1ju);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 12px;
  top: 7px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
.patient-registration .cancel-button-txmB1b {
  background-color: transparent;
  bottom: 46px;
  height: 26px;
  position: absolute;
  right: calc(37.38% - 31px);
  width: 84px;
}
.patient-registration .rectangle-47166-jELxVt {
  background-color: transparent;
  border: 1px solid #0063e7;
  border: 1px solid var(--blue-ribbon-2kgi7);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .cancel-jELxVt {
  background-color: transparent;
  color: #0063e7;
  color: var(--blue-ribbon-2kgi7);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 16px;
  font-size: var(--font-size-xl-c0xm8);
  font-style: normal;
  font-weight: 400;
  height: calc(100% - 3px);
  letter-spacing: 0px;
  line-height: 37px;
  position: absolute;
  right: calc(48.08% - 25px);
  text-align: left;
  top: -6px;
  white-space: nowrap;
  width: 51px;
}
.patient-registration .patient-registration-txmB1b {
  background-color: transparent;
  color: #000000;
  color: var(--black-a6xpv);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 16px;
  font-size: var(--font-size-xl-c0xm8);
  font-style: normal;
  font-weight: 500;
  height: 23px;
  left: 35px;
  letter-spacing: 0px;
  line-height: 16px;
  position: absolute;
  text-align: left;
  top: 22px;
  white-space: nowrap;
  width: 144px;
}
.patient-registration .path-93228-zVTIDx {
  background-color: transparent;
  height: 1px;
  object-fit: cover;
  position: absolute;
  right: calc(36.57% - 196px);
  top: 155px;
  width: 535px;
}
.patient-registration .group-93721-zVTIDx {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.patient-registration .rectangle-47180-C1tNJq {
  background-color: #ffffff;
  background-color: var(--white-buigd);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .login-avatar-C1tNJq {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: calc(50% - 18px);
  width: 134px;
}
.patient-registration .rectangle-47177-QIQaxl {
  background-color: transparent;
  border: 0px solid #0063e7;
  border: 0px solid var(--blue-ribbon-2kgi7);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .rectangle-47178-QIQaxl {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.patient-registration .rakin-QIQaxl {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-2kgi7);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.patient-registration .icon-awesome-chevron-down-QIQaxl {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.patient-registration .patient-registration-C1tNJq {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-a6xpv);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 18px;
  font-size: var(--font-size-xxl-fqp1a);
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 161px;
}
.patient-registration .group-93720-zVTIDx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
}
.patient-registration .rectangle-47173-LirMrN {
  background-color: #ffffff;
  background-color: var(--white-buigd);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.patient-registration .group-93719-LirMrN {
  background-color: transparent;
  height: 420px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 197px;
}
.patient-registration .logo-oSnKxZ {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 17px);
}
.patient-registration .x51-client_profile_subscription-10-wYxAZU {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.patient-registration .global-doctors-wYxAZU {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-2kgi7);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 23px;
  font-size: var(--font-size-xxxxl-wlhux);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.patient-registration .span0-2KEHE5 {
  font-family: var(--font-family-roboto-4kl4n-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.patient-registration .span1-2KEHE5 {
  font-size: 19px;
  font-size: var(--font-size-xxxl-6h4pm);
  font-style: normal;
}
.patient-registration .rectangle-47179-oSnKxZ {
  background-color: #fff9ed;
  border-radius: 5px;
  bottom: calc(44.5% - 17px);
  height: 38px;
  left: 7px;
  position: absolute;
  width: calc(100% - 12px);
}
.patient-registration .version-oSnKxZ {
  background-color: transparent;
  height: 13px;
  position: absolute;
  right: 0px;
  top: 61px;
  width: 33px;
}
.patient-registration .v1001-hO8D8V {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 10px;
  font-size: var(--font-size-s-nla82);
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.patient-registration .login-details-oSnKxZ {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(27.87% - 26px);
  width: 148px;
}
.patient-registration .hello-IgMki6 {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 15.2px;
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.patient-registration .mr-rakin-IgMki6 {
  background-color: transparent;
  color: #000000;
  color: var(--black-a6xpv);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.patient-registration .sathishrakinainqacom-IgMki6 {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-a6xpv);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.patient-registration .line-9-IgMki6 {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.patient-registration .reports-oSnKxZ {
  background-color: transparent;
  bottom: 0px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.patient-registration .reports-74itnq {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.patient-registration .download-7-74itnq {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.patient-registration .patient-regn-oSnKxZ {
  background-color: transparent;
  bottom: calc(44.75% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 40px);
}
.patient-registration .patient-registration-NmGpcR {
  background-color: transparent;
  color: #000000;
  color: var(--black-a6xpv);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 14px;
  font-size: var(--font-size-l-0h1ju);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.patient-registration .rectangle-47175-NmGpcR {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.patient-registration .master-oSnKxZ {
  background-color: transparent;
  bottom: 60px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.patient-registration .master-fgeYR1 {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.patient-registration .material-master-data-fgeYR1 {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.patient-registration .visit-regn-oSnKxZ {
  background-color: transparent;
  bottom: calc(29.93% - 6px);
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.patient-registration .visit-registration-PhuJQ4 {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.patient-registration .download-6-PhuJQ4 {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.roboto-light-pink-swan-12px {
  color: #b6b6b6;
  color: var(--pink-swan-xim82);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 12px;
  font-size: var(--font-size-m-aw07k);
  font-style: normal;
  font-weight: 300;
}
.roboto-medium-dove-gray-14px {
  color: #6d6e6f;
  color: var(--dove-gray-e5xhq);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-4kl4n);
  font-size: 14px;
  font-size: var(--font-size-l-0h1ju);
  font-style: normal;
  font-weight: 500;
}
.border-1px-bon-jour {
  border: 1px solid #e0e0e0;
  border: 1px solid var(--bon-jour-pv96a);
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183739857) */

:root {
  --font-family-roboto-condensedbold-cv59m: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-24jqg: "Roboto", Helvetica;
  --font-family-poppins-ke4w9: "Poppins", Helvetica;
  --font-size-xxxl-pcd5h: 40px;
  --font-size-xxl-4fl4f: 23px;
  --font-size-xl-4m2mj: 19px;
  --font-size-s-ony5z: 11px;
  --font-size-m-tu70e: 12px;
  --font-size-l-ui6ed: 18px;
  --white-iwlcf: #ffffff;
  --blue-ribbon-k7qsj: #0063e7;
  --black-rm86d: #000000;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}

.passwordField div input {
  -webkit-text-security: disc;
}
input:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
.login {
  background-color: #ffffff;
  background-color: var(--white-iwlcf);
  height: 100vh;
  margin: 0px;
  /* min-height: 768px; */
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.login .path-93227-b8DrbD {
  background-color: transparent;
  bottom: -144px;
  height: 51.54%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  width: calc(100% + 60px);
}
.login .group-93715-b8DrbD {
  background-color: transparent;
  height: 451px;
  position: absolute;
  right: 130px;
  top: calc(48.97% - 221px);
  width: 400px;
}
.login .welcome-bgeoTh {
  background-color: transparent;
  color: #252525;
  font-family: "Poppins", Helvetica;
  font-family: var(--font-family-poppins-ke4w9);
  font-size: 40px;
  font-size: var(--font-size-xxxl-pcd5h);
  font-style: normal;
  font-weight: 600;
  height: 58px;
  letter-spacing: 0px;
  line-height: 40px;
  position: absolute;
  right: calc(49.55% - 97px);
  text-align: left;
  top: 8px;
  white-space: nowrap;
  width: 195px;
}
.login .qdm-textinput-bgeoTh {
  background-color: transparent;
  height: 67px;
  left: 1px;
  position: absolute;
  top: calc(35.69% - 24px);
  width: 100%;
}
.login .textinputbg-d10Yxh {
  background-color: #ffffff;
  background-color: var(--white-iwlcf);
  border: 0.8px solid #e0e0e0;
  border-radius: 5px;
  bottom: 0px;
  height: 40px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.login .user-name-d10Yxh {
  background-color: transparent;
  border: 0;
  bottom: calc(21.13% - 4px);
  color: #b6b6b6;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-24jqg);
  font-size: 11px;
  font-size: var(--font-size-s-ony5z);
  font-style: normal;
  font-weight: 400;
  height: 17px;
  left: 32px;
  letter-spacing: 0.26px;
  line-height: 11px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 57px;
}
.login .user-name-d10Yxh::-webkit-input-placeholder {
  color: #b6b6b699;
}
.login .user-name-d10Yxh::placeholder {
  color: #b6b6b699;
}
.login .email-d10Yxh {
  background-color: transparent;
  border: 0;
  color: #000000;
  color: var(--black-rm86d);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-24jqg);
  font-size: 12px;
  font-size: var(--font-size-m-tu70e);
  font-style: normal;
  font-weight: 500;
  height: 18px;
  left: 0px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 0px;
  white-space: nowrap;
  width: 39px;
}
.login .email-d10Yxh::-webkit-input-placeholder {
  color: #00000099;
}
.login .email-d10Yxh::placeholder {
  color: #00000099;
}
.login .icon-awesome-user-alt-d10Yxh {
  background-color: transparent;
  bottom: calc(27.24% - 3px);
  height: 12px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  width: 12px;
}
.login .qdm-textinput-Cxvxcm {
  background-color: transparent;
  bottom: calc(41.18% - 27px);
  height: 67px;
  left: 1px;
  position: absolute;
  width: 100%;
}
.login .textinputbg-eqF7Aq {
  background-color: #ffffff;
  background-color: var(--white-iwlcf);
  border: 0.5px solid #e0e0e0;
  border-radius: 5px;
  bottom: 0px;
  height: 40px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.login .password-eqF7Aq {
  background-color: transparent;
  border: 0;
  bottom: calc(21.13% - 4px);
  color: #b6b6b6;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-24jqg);
  font-size: 11px;
  font-size: var(--font-size-s-ony5z);
  font-style: normal;
  font-weight: 400;
  height: 17px;
  left: 30px;
  letter-spacing: 0.26px;
  line-height: 11px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  white-space: nowrap;
  width: 53px;
}
.login .password-eqF7Aq::-webkit-input-placeholder {
  color: #b6b6b699;
}
.login .password-eqF7Aq::placeholder {
  color: #b6b6b699;
}
.login .password-IeidnB {
  background-color: transparent;
  border: 0;
  color: #000000;
  color: var(--black-rm86d);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-24jqg);
  font-size: 12px;
  font-size: var(--font-size-m-tu70e);
  font-style: normal;
  font-weight: 500;
  height: 18px;
  left: 0px;
  letter-spacing: 0.29px;
  line-height: 12px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 0px;
  white-space: nowrap;
  width: 63px;
}
.login .password-IeidnB::-webkit-input-placeholder {
  color: #00000099;
}
.login .password-IeidnB::placeholder {
  color: #00000099;
}
.login .icon-ionic-ios-lock-eqF7Aq {
  background-color: transparent;
  bottom: calc(25.91% - 4px);
  height: 15px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  width: 11px;
}
.login .qdm-button-bgeoTh {
  background-color: transparent;
  bottom: 0px;
  height: 40px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.login .rectangle-22-qwfgee {
  background-color: #0569ffe6;
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.login .sign-in-qwfgee {
  margin-top: -40px;
  background-color: #0569ffe6;
  color: #ffffff;
  color: var(--white-iwlcf);
  font-family: "Poppins", Helvetica;
  font-family: var(--font-family-poppins-ke4w9);
  font-size: 18px;
  font-size: var(--font-size-l-ui6ed);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 18px;
  white-space: nowrap;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
.login .forgot-password-bgeoTh {
  background-color: transparent;
  bottom: calc(24.61% - 4px);
  color: #0569ff;
  font-family: "Poppins", Helvetica;
  font-family: var(--font-family-poppins-ke4w9);
  font-size: 11px;
  font-size: var(--font-size-s-ony5z);
  font-style: normal;
  font-weight: 500;
  height: 18px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 103px;
}
.login .sign-in-to-your-acco-bgeoTh {
  background-color: transparent;
  color: #696969;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-24jqg);
  font-size: 12px;
  font-size: var(--font-size-m-tu70e);
  font-style: normal;
  font-weight: 400;
  height: 18px;
  left: 28px;
  letter-spacing: 0px;
  line-height: 12px;
  position: absolute;
  text-align: left;
  top: calc(19.91% - 4px);
  white-space: nowrap;
  width: calc(100% - 56px);
}
.login .group-93714-bgeoTh {
  background-color: transparent;
  bottom: calc(25.53% - 4px);
  height: 16px;
  left: 1px;
  position: absolute;
  width: 116px;
}
.login .remember-me-xa4FNl {
  background-color: transparent;
  color: #000000;
  color: var(--black-rm86d);
  font-family: "Poppins", Helvetica;
  font-family: var(--font-family-poppins-ke4w9);
  font-size: 11px;
  font-size: var(--font-size-s-ony5z);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 85px;
}
.login .rectangle-47172-xa4FNl {
  background-color: #ffffff;
  background-color: var(--white-iwlcf);
  border: 1px solid #070000;
  border-radius: 3px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.login .group-93723-b8DrbD {
  background-color: transparent;
  height: 418px;
  left: 54px;
  position: absolute;
  top: calc(50% - 209px);
  width: 597px;
}
.login .undraw_medicine_b1ol-qr1pd2 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.login .group-93713-b8DrbD {
  background-color: transparent;
  height: 50px;
  left: 37px;
  position: absolute;
  top: 31px;
  width: 180px;
}
.login .x51-client_profile_subscription-10-h0zH23 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.login .global-doctors-h0zH23 {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-k7qsj);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-24jqg);
  font-size: 23px;
  font-size: var(--font-size-xxl-4fl4f);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.login .span0-3GZPF5 {
  font-family: var(--font-family-roboto-24jqg-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.login .span1-3GZPF5 {
  font-size: 19px;
  font-size: var(--font-size-xl-4m2mj);
  font-style: normal;
}

/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183801927) */

:root {
  --font-family-roboto-condensedbold-ca3n0: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-h7mcm: "Roboto", Helvetica;
  --font-size-xxxxl-3ug42: 23px;
  --font-size-xxxl-aa1a2: 20px;
  --font-size-xxl-wfilm: 19px;
  --font-size-xl-xgk7a: 18px;
  --font-size-s-mjv1a: 11px;
  --font-size-m-v72pv: 14px;
  --font-size-l-fgw2k: 15.2px;
  --woodsmoke-672ch: #101010;
  --white-yzlko: #ffffff;
  --dove-gray-2-mf2p4: #6d6e6f;
  --dove-gray-r8njp: #6f6f6f;
  --blue-ribbon-lzwx2: #0063e7;
  --black-7fwu0: #000000;
}
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/RobotoCondensed-Regular.52ee8b59.ttf) format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
* {
  box-sizing: border-box;
}
.reports {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.reports .group-93718-3zrh10 {
  background-color: white;
  border-radius: 5px;
  height: auto;
  left: 236px;
  position: absolute;
  top: 111px;
  padding: 12px 12px 5px;
  display: grid;
  width: calc(100% - 245px);
}
.reports .group-93718-3zrh11 {
  background-color: transparent;
  height: calc(100% - 146px);
  left: 236px;
  position: absolute;
  top: 264px;
  display: grid;
  width: calc(100% - 245px);
}
.reports .rectangle-47148-zcRaaK {
  background-color: #ffffff;
  background-color: var(--white-yzlko);
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.reports .group-93702-zcRaaK {
  background-color: transparent;
  height: 2.9%;
  left: 60px;
  position: absolute;
  top: 17px;
  width: calc(100% - 140px);
}
.reports .name-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 39px;
}
.reports .mrn-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  left: 136px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 33px;
}
.reports .encounter-no-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  left: calc(30.43% - 26px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 87px;
}
.reports .date-time-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(49.06% - 42px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 78px;
}
.reports .duration-of-session-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(20.56% - 34px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 125px;
}
.reports .consultant-cVadP6 {
  background-color: transparent;
  height: calc(100% + 4px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -6px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 70px;
}
.reports .line-2-zcRaaK {
  background-color: transparent;
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 44px;
  width: calc(100% - 6px);
}
.reports .qdm-list-zcRaaK {
  background-color: transparent;
  left: 37px;
  position: absolute;
  top: 53px;
  width: 100%;
  height: 96vh;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: scroll;
}
.reports .listitem-9n1jZJ {
  background-color: transparent;
  position: relative;
  width: calc(100% - 88px);
  height: 32px;
  margin: 0px 10px 10px 0px;
}
.reports .administrator-eXCdcl {
  background-color: transparent;
  height: 17px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 69px;
}
.reports .x56789568-eXCdcl {
  background-color: transparent;
  height: 17px;
  left: 151px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.reports .x3-eXCdcl {
  background-color: transparent;
  height: 17px;
  left: calc(32.2% - 10px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 8px;
}
.reports .x12122021-0900-pm-eXCdcl {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(49.23% - 118px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 119px;
}
.reports .x3000-mins-eXCdcl {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(25.28% - -40px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 56px;
}
.reports .specialist-eXCdcl {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: 28px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 50px;
}
.reports .line-3-eXCdcl {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
.reports .group-93712-3zrh10 {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.reports .rectangle-47180-IEZVGu {
  background-color: #ffffff;
  background-color: var(--white-yzlko);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}
.reports .login-avatar-IEZVGu {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 134px;
}
.reports .rectangle-47177-BShlaI {
  background-color: transparent;
  border: 0.7px solid #0063e7;
  border: 0.7px solid var(--blue-ribbon-lzwx2);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.reports .rectangle-47178-BShlaI {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.reports .rakin-BShlaI {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: #0063e7;
  color: var(--blue-ribbon-lzwx2);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 14px;
  font-size: var(--font-size-m-v72pv);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.reports .icon-awesome-chevron-down-BShlaI {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.reports .reports-IEZVGu {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: #000000;
  color: var(--black-7fwu0);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 18px;
  font-size: var(--font-size-xl-xgk7a);
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 65px;
}
.reports .group-93711-3zrh10 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
  border-right: 1px solid lightgrey;
}
.reports .rectangle-47173-glUWHL {
  background-color: #ffffff;
  background-color: var(--white-yzlko);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.reports .group-93715-glUWHL {
  background-color: transparent;
  height: 434px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.reports .logo-38rwxk {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.reports .x51-client_profile_subscription-10-Nixsk1 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.reports .global-doctors-Nixsk1 {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: #0063e7;
  color: var(--blue-ribbon-lzwx2);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 23px;
  font-size: var(--font-size-xxxxl-3ug42);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.reports .span0-c7eoPE {
  font-family: var(--font-family-roboto-h7mcm-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.reports .span1-c7eoPE {
  font-size: 19px;
  font-size: var(--font-size-xxl-wfilm);
  font-style: normal;
}
.reports .rectangle-47179-38rwxk {
  background-color: #ece3d3;
  border-radius: 5px;
  bottom: 50px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.reports .login-details-38rwxk {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(26.73% - 25px);
  width: 148px;
}
.reports .hello-axe3dy {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 15.2px;
  font-size: var(--font-size-l-fgw2k);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.reports .mr-rakin-axe3dy {
  background-color: transparent;
  color: #000000;
  color: var(--black-7fwu0);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.reports .sathishrakinainqacom-axe3dy {
  background-color: transparent;
  bottom: 9px;
  color: #000000;
  color: var(--black-7fwu0);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.reports .line-9-axe3dy {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.reports .reports-38rwxk {
  background-color: transparent;
  bottom: 60px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.reports .reports-XiX9S9 {
  background-color: transparent;
  color: #000000;
  color: var(--black-7fwu0);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 14px;
  font-size: var(--font-size-m-v72pv);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.reports .download-7-XiX9S9 {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.reports .patient-regn-38rwxk {
  background-color: transparent;
  bottom: calc(45.65% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.reports .patient-registration-03Eyjx {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.reports .rectangle-47175-03Eyjx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.reports .master-38rwxk {
  background-color: transparent;
  bottom: 124px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.reports .master-QjIt1f {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.reports .material-master-data-QjIt1f {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.reports .group-93714-38rwxk {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.reports .visit-registration-3JfVI6 {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.reports .download-6-3JfVI6 {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.reports .version-3zrh10 {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.reports .v1001-3jxhjo {
  background-color: transparent;
  color: #9297a5;
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.roboto-medium-woodsmoke-14px {
  color: #101010;
  color: var(--woodsmoke-672ch);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 14px;
  font-size: var(--font-size-m-v72pv);
  font-style: normal;
  font-weight: 500;
}
.roboto-normal-dove-gray-11px {
  color: #6f6f6f;
  color: var(--dove-gray-r8njp);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 11px;
  font-size: var(--font-size-s-mjv1a);
  font-style: normal;
  font-weight: 400;
}
.roboto-medium-dove-gray-14px {
  color: var(--dove-gray-r8njp-2);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 14px;
  font-size: var(--font-size-m-v72pv);
  font-style: normal;
  font-weight: 500;
}

