/* @import url(https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183801927) */

:root {
  --font-family-roboto-condensedbold-ca3n0: "Roboto-CondensedBold", Helvetica;
  --font-family-roboto-h7mcm: "Roboto", Helvetica;
  --font-size-xxxxl-3ug42: 23px;
  --font-size-xxxl-aa1a2: 20px;
  --font-size-xxl-wfilm: 19px;
  --font-size-xl-xgk7a: 18px;
  --font-size-s-mjv1a: 11px;
  --font-size-m-v72pv: 14px;
  --font-size-l-fgw2k: 15.2px;
  --woodsmoke-672ch: #101010;
  --white-yzlko: #ffffff;
  --dove-gray-2-mf2p4: #6d6e6f;
  --dove-gray-r8njp: #6f6f6f;
  --blue-ribbon-lzwx2: #0063e7;
  --black-7fwu0: #000000;
}
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600|Roboto:400,300,500");
@font-face {
  font-family: "Roboto-CondensedBold";
  font-style: normal;
  font-weight: 700;
  src: url("../../font/RobotoCondensed-Regular.ttf") format("truetype");
}
.screen textarea:focus,
.screen input:focus {
  outline: none;
}
.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
.screen div {
  -webkit-text-size-adjust: none;
}
* {
  box-sizing: border-box;
}
.reports {
  background-color: #f2f4f8;
  height: 100vh;
  margin: 0px;
  min-height: 800px;
  min-width: 1200px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.reports .group-93718-3zrh10 {
  background-color: white;
  border-radius: 5px;
  height: auto;
  left: 236px;
  position: absolute;
  top: 111px;
  padding: 12px 12px 5px;
  display: grid;
  width: calc(100% - 245px);
}
.reports .group-93718-3zrh11 {
  background-color: transparent;
  height: calc(100% - 146px);
  left: 236px;
  position: absolute;
  top: 264px;
  display: grid;
  width: calc(100% - 245px);
}
.reports .rectangle-47148-zcRaaK {
  background-color: var(--white-yzlko);
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.reports .group-93702-zcRaaK {
  background-color: transparent;
  height: 2.9%;
  left: 60px;
  position: absolute;
  top: 17px;
  width: calc(100% - 140px);
}
.reports .name-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 39px;
}
.reports .mrn-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  left: 136px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 33px;
}
.reports .encounter-no-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  left: calc(30.43% - 26px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 87px;
}
.reports .date-time-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(49.06% - 42px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 78px;
}
.reports .duration-of-session-cVadP6 {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: calc(20.56% - 34px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 125px;
}
.reports .consultant-cVadP6 {
  background-color: transparent;
  height: calc(100% + 4px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -6px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 70px;
}
.reports .line-2-zcRaaK {
  background-color: transparent;
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 44px;
  width: calc(100% - 6px);
}
.reports .qdm-list-zcRaaK {
  background-color: transparent;
  left: 37px;
  position: absolute;
  top: 53px;
  width: 100%;
  height: 96vh;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: scroll;
}
.reports .listitem-9n1jZJ {
  background-color: transparent;
  position: relative;
  width: calc(100% - 88px);
  height: 32px;
  margin: 0px 10px 10px 0px;
}
.reports .administrator-eXCdcl {
  background-color: transparent;
  height: 17px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 69px;
}
.reports .x56789568-eXCdcl {
  background-color: transparent;
  height: 17px;
  left: 151px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.reports .x3-eXCdcl {
  background-color: transparent;
  height: 17px;
  left: calc(32.2% - 10px);
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 8px;
}
.reports .x12122021-0900-pm-eXCdcl {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(49.23% - 118px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 119px;
}
.reports .x3000-mins-eXCdcl {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: calc(25.28% - -40px);
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 56px;
}
.reports .specialist-eXCdcl {
  background-color: transparent;
  height: 17px;
  letter-spacing: 0px;
  line-height: 11px;
  position: absolute;
  right: 28px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 50px;
}
.reports .line-3-eXCdcl {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
.reports .group-93712-3zrh10 {
  background-color: transparent;
  height: 71px;
  left: 222px;
  position: absolute;
  top: 0px;
  width: calc(100% - 222px);
}
.reports .rectangle-47180-IEZVGu {
  background-color: var(--white-yzlko);
  height: 71px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}
.reports .login-avatar-IEZVGu {
  background-color: transparent;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 134px;
}
.reports .rectangle-47177-BShlaI {
  background-color: transparent;
  border: 0.7px solid var(--blue-ribbon-lzwx2);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.reports .rectangle-47178-BShlaI {
  background-color: transparent;
  border-radius: 5px;
  height: calc(100% - 6px);
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 29px;
}
.reports .rakin-BShlaI {
  background-color: transparent;
  bottom: calc(32.14% - 7px);
  color: var(--blue-ribbon-lzwx2);
  font-family: var(--font-family-roboto-h7mcm);
  font-size: var(--font-size-m-v72pv);
  font-style: normal;
  font-weight: 300;
  height: 21px;
  left: calc(46.74% - 20px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 42px;
}
.reports .icon-awesome-chevron-down-BShlaI {
  background-color: transparent;
  height: 5px;
  object-fit: cover;
  position: absolute;
  right: 11px;
  top: calc(48.4% - 3px);
  width: 9px;
}
.reports .reports-IEZVGu {
  background-color: transparent;
  bottom: calc(40% - 10px);
  color: var(--black-7fwu0);
  font-family: var(--font-family-roboto-h7mcm);
  font-size: var(--font-size-xl-xgk7a);
  font-style: normal;
  font-weight: 500;
  height: 26px;
  left: 32px;
  letter-spacing: 0px;
  line-height: 18px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 65px;
}
.reports .group-93711-3zrh10 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 221px;
  border-right: 1px solid lightgrey;
}
.reports .rectangle-47173-glUWHL {
  background-color: var(--white-yzlko);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.reports .group-93715-glUWHL {
  background-color: transparent;
  height: 434px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 193px;
}
.reports .logo-38rwxk {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100% - 13px);
}
.reports .x51-client_profile_subscription-10-Nixsk1 {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 50px;
}
.reports .global-doctors-Nixsk1 {
  background-color: transparent;
  bottom: calc(8.33% - 3px);
  color: var(--blue-ribbon-lzwx2);
  font-family: var(--font-family-roboto-h7mcm);
  font-size: var(--font-size-xxxxl-3ug42);
  font-weight: 400;
  height: 32px;
  letter-spacing: 0px;
  line-height: 19px;
  position: absolute;
  right: -2px;
  text-align: left;
  white-space: nowrap;
  width: 132px;
}
.reports .span0-c7eoPE {
  font-family: var(--font-family-roboto-h7mcm-condensedbold);
  font-style: normal;
  font-weight: 700;
}
.reports .span1-c7eoPE {
  font-size: var(--font-size-xxl-wfilm);
  font-style: normal;
}
.reports .rectangle-47179-38rwxk {
  background-color: #ece3d3;
  border-radius: 5px;
  bottom: 50px;
  height: 38px;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}
.reports .login-details-38rwxk {
  background-color: transparent;
  height: 94px;
  left: 12px;
  position: absolute;
  top: calc(26.73% - 25px);
  width: 148px;
}
.reports .hello-axe3dy {
  background-color: transparent;
  color: #9297a5;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: var(--font-size-l-fgw2k);
  font-style: normal;
  font-weight: 400;
  height: 22px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 15.2px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 40px;
}
.reports .mr-rakin-axe3dy {
  background-color: transparent;
  color: var(--black-7fwu0);
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  position: absolute;
  text-align: left;
  top: calc(47.33% - 13px);
  white-space: nowrap;
  width: 80px;
}
.reports .sathishrakinainqacom-axe3dy {
  background-color: transparent;
  bottom: 9px;
  color: var(--black-7fwu0);
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  left: 1px;
  letter-spacing: 0px;
  line-height: 13px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.reports .line-9-axe3dy {
  background-color: transparent;
  bottom: 0px;
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  width: 23px;
}
.reports .reports-38rwxk {
  background-color: transparent;
  bottom: 60px;
  height: 19px;
  left: 20px;
  position: absolute;
  width: 80px;
}
.reports .reports-XiX9S9 {
  background-color: transparent;
  color: var(--black-7fwu0);
  font-family: var(--font-family-roboto-h7mcm);
  font-size: var(--font-size-m-v72pv);
  font-style: normal;
  font-weight: 500;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 51px;
}
.reports .download-7-XiX9S9 {
  background-color: transparent;
  height: calc(100% - 3px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 16px;
}
.reports .patient-regn-38rwxk {
  background-color: transparent;
  bottom: calc(45.65% - 9px);
  height: 20px;
  left: 18px;
  position: absolute;
  width: calc(100% - 36px);
}
.reports .patient-registration-03Eyjx {
  background-color: transparent;
  height: calc(100% + 1px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 126px;
}
.reports .rectangle-47175-03Eyjx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 20px;
}
.reports .master-38rwxk {
  background-color: transparent;
  bottom: 124px;
  height: 19px;
  left: 18px;
  position: absolute;
  width: 77px;
}
.reports .master-QjIt1f {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 46px;
}
.reports .material-master-data-QjIt1f {
  background-color: transparent;
  height: calc(100% - 1px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 18px;
}
.reports .group-93714-38rwxk {
  background-color: transparent;
  top: 226px;
  height: 19px;
  left: 19px;
  position: absolute;
  width: 139px;
}
.reports .visit-registration-3JfVI6 {
  background-color: transparent;
  height: calc(100% + 2px);
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  right: -2px;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: 109px;
}
.reports .download-6-3JfVI6 {
  background-color: transparent;
  height: calc(100% - 2px);
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 16px;
}
.reports .version-3zrh10 {
  background-color: transparent;
  height: 13px;
  left: 180px;
  position: absolute;
  top: 73px;
  width: 33px;
}
.reports .v1001-3jxhjo {
  background-color: transparent;
  color: #9297a5;
  font-family: var(--font-family-roboto-h7mcm);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  height: calc(100% + 2px);
  left: 0px;
  letter-spacing: 0px;
  line-height: 10px;
  position: absolute;
  text-align: left;
  top: 2px;
  white-space: nowrap;
  width: calc(100% + 2px);
}
.roboto-medium-woodsmoke-14px {
  color: var(--woodsmoke-672ch);
  font-family: var(--font-family-roboto-h7mcm);
  font-size: var(--font-size-m-v72pv);
  font-style: normal;
  font-weight: 500;
}
.roboto-normal-dove-gray-11px {
  color: var(--dove-gray-r8njp);
  font-family: var(--font-family-roboto-h7mcm);
  font-size: var(--font-size-s-mjv1a);
  font-style: normal;
  font-weight: 400;
}
.roboto-medium-dove-gray-14px {
  color: var(--dove-gray-r8njp-2);
  font-family: var(--font-family-roboto-h7mcm);
  font-size: var(--font-size-m-v72pv);
  font-style: normal;
  font-weight: 500;
}
